import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getViewStates } from "features/layout/Selectors";
import { MyConversations } from "features/joinedConversations/MyConversations/MyConversations";
import { ThemeContext } from "styled-components";
import { useMediaQuery } from "foundations/hooks/useMediaQuery";
import { MyUserDetails } from "features/currentUser/MyUserDetails";
import { menuViewHidden } from "features/layout/LayoutActions";
import { FeedbackModal } from "../../bootstrap/feedback-modal/FeedbackModal";
import CustomizedSwitches from "../../bootstrap/customized-switches/CustomizedSwitches";
import FontOutput from "../../bootstrap/text-input/TextInput";
import ToggleSettings from "../../bootstrap/toggle-settings/ToggleSettings";
import {
  Icon,
  Icons,
  Heading,
  HeadingVariants
} from "foundations/components/presentation";
import {
  Drawer,
  StyledBox,
  FlexRow,
  FlexColumn
} from "foundations/components/layout";
import logo from "../../../styles/dp-logo.gif";
// export default function SwitchLabels() {
//   const [state, setState] = React.useState({
//     checkedA: true,
//     checkedB: true,
//   });

interface IsecondChildProps {
  count: string;
  updateCount: (arg: string) => void;
}

const Menu: React.FC<IsecondChildProps> = ({ count, updateCount }) => {
  const [pageCount, setPageCount] = useState<string>("");
  useEffect(() => {
    setPageCount(count);
  }, [count]);
  const views = useSelector(getViewStates);
  const theme = useContext(ThemeContext);
  const isMedium = useMediaQuery(theme.mediaQueries.medium);
  const dispatch = useDispatch();

  if (count === "1") {
    document.documentElement.style.setProperty("--buttonOne", "#E6F2F0");
    document.documentElement.style.setProperty("--buttonTwo", "transparent");
    document.documentElement.style.setProperty("--buttonThree", "transparent");
  } else if (count === "2") {
    document.documentElement.style.setProperty("--buttonTwo", "#E6F2F0");
    document.documentElement.style.setProperty("--buttonOne", "transparent");
    document.documentElement.style.setProperty("--buttonThree", "transparent");
  } else if (count === "3") {
    document.documentElement.style.setProperty("--buttonThree", "#E6F2F0");
    document.documentElement.style.setProperty("--buttonOne", "transparent");
    document.documentElement.style.setProperty("--buttonTwo", "transparent");
  }

  //console.log("it was clicked");

  return (
    <Drawer
      open={views.Menu || isMedium}
      background={theme.backgrounds.primary}
    >
      <StyledBox
        position="absolute"
        right="0"
        padding="6"
        style={{ width: "100%" }}
        display={["block", "none"]}
      >
        <Icon
          onClick={() => dispatch(menuViewHidden())}
          icon={Icons.Cross}
          title="Close conversations"
          color={theme.colors.onPrimary}
          clickable
        />
      </StyledBox>
      <div
        style={{
          display: "flex",
          fontFamily: "'Space Grotesk' !important",
          alignItems: "flex-start",
          width: "100%",
          flexDirection: "row",
          maxWidth: "260px",
          flexWrap: "wrap",
          height: "100%"
        }}
      >
        <StyledBox padding={6} style={{ width: "100%", height: "0" }}>
          <FlexRow>
            <img src={logo} />
          </FlexRow>
          <FlexRow>
            <StyledBox>
              <FlexColumn minHeight={1}>
                <Heading variant={HeadingVariants.INVERSE}>
                  <p style={{ fontSize: "1.5em", marginBottom: "0" }}>
                    {theme.custom.companyName}
                  </p>
                  <p style={{ fontSize: "1.0em" }}>
                    Type that responds to you.
                  </p>
                </Heading>
                {/* <MyUserDetails /> */}
              </FlexColumn>
            </StyledBox>
          </FlexRow>
        </StyledBox>
        <div style={{ width: "100%" }}>
          <div
            style={{
              background: "transparent",
              paddingBottom: "18%",
              paddingTop: "12%",
              color: "black",
              width: "100%",
              textAlign: "left"
            }}
          >
            <button onClick={() => updateCount("1")} id="nbone">
              Chat Room
            </button>
            <CustomizedSwitches />
          </div>

          <div
            style={{
              background: "transparent",
              paddingBottom: "18%",
              color: "black",
              width: "100%",
              textAlign: "left"
            }}
          >
            <button id="nbtwo" onClick={() => updateCount("2")}>
              About
            </button>
          </div>

          <div
            style={{
              background: "transparent",
              paddingBottom: "18%",
              color: "black",
              width: "100%",
              textAlign: "left"
            }}
          >
            <button id="nbthree" onClick={() => updateCount("3")}>
              Feedback
            </button>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            alignSelf: "flex-end",
            paddingBottom: "15%",
            paddingLeft: "10%",
            paddingRight: "10%"
          }}
        >
          <p
            style={{ color: "#7E5A7E", fontSize: "0.6em", lineHeight: "1.5em" }}
          >
            <b>
              Designed and Developed by <br /> Arielle Cerini{" "}
            </b>
          </p>
          <a
            href="http://acerinidesigns.com"
            target="_blank"
            style={{ color: "#7E5A7E", fontSize: "0.6em" }}
          >
            <b>acerinidesigns.com </b>
          </a>
        </div>
      </div>
    </Drawer>
  );
};

export { Menu };

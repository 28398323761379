//calculating features

import { fullLog } from "./typing";

var D = 0;
var Ftype1 = 0;
var Ftype2 = 0;
var Ftype3 = 0;
var Ftype4 = 0;

function renderFeatures(data, press) {
  var R1 = fullLog[press - 1].release;
  var P1 = fullLog[press - 1].start;
  var R2 = fullLog[press].release;
  var P2 = fullLog[press].start;

  // D = R1 - P1
  D = R1 - P1;

  // console.log(D);

  //Ftype1 = P2 - R1
  Ftype1 = P2 - R1;
  // console.log(Ftype1);

  //Ftype2 = R2 - R1
  Ftype2 = R2 - R1;
  // console.log(Ftype2);

  //Ftype3 = P2 - P1
  Ftype3 = P2 - P1;
  // console.log(Ftype3);

  //Ftype4 = R2 - P1
  Ftype4 = R2 - P1;
  // console.log(Ftype4);

  fullLog[press].D = D;
  fullLog[press].Ftype1 = Ftype1;
  fullLog[press].Ftype2 = Ftype2;
  fullLog[press].Ftype3 = Ftype3;
  fullLog[press].Ftype4 = Ftype4;

  return {
    0: D,
    1: Ftype1,
    2: Ftype2,
    3: Ftype3,
    4: Ftype4
  };
}
export { renderFeatures };

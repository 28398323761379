import fullLog from "./typing";
import {
  minSlant,
  maxSlant,
  averageSlant
} from "./font-interpolation/letter-angle";
import { rateList } from "./font-interpolation/weight-mapping";
import * as math from "mathjs";

function letterSlant(averageSlant) {
  let angleVal = "";
  if (Math.round(averageSlant) < 0) {
    angleVal =
      "\n This users keystrokes are weighted to the <b>left-hand side</b>, this suggests that their <b>left hand may be the more dominant one</b> when typing.";
  }
  if (Math.round(averageSlant) > 0) {
    angleVal =
      "\n This users keystrokes are weighted to the <b>right-hand side</b>, this suggests that their <b>right hand may be the dominant one</b> when typing.";
  }
  if (Math.round(averageSlant) === 0) {
    angleVal =
      "\n This users keystrokes are not weighted to the right or left, which suggests that <b>both hands are equally dominant</b> when they type.";
  }

  return angleVal;
}
let sendNumber = 0;
let dataList = {};
let pressVal = 0;
export default function getSpecData() {
  // console.log(rateList);
  if (sendNumber <= 1) {
    dataList = rateList;
  } else {
    dataList = rateList.slice(pressVal + 2);
  }
  const letterSpec = letterSlant(averageSlant);
  let maxSpeed = math.max(dataList);
  let minSpeed = math.min(dataList);
  let averageSpeed = math.mean(dataList);
  const speedSpec =
    "<b>Max Typing Speed: </b>" +
    Math.round(maxSpeed * 10) / 10 +
    " keys per second<br/> <b>Min Typing Speed: </b>" +
    Math.round(minSpeed * 10) / 10 +
    " keys per second<br/><b> Average Typing Speed: </b>" +
    Math.round(averageSpeed * 10) / 10 +
    " keys per second <br/>";

  //   console.log(speedSpec);
  const outputtedSpecs = speedSpec + letterSpec;
  pressVal = rateList.length - 1;
  sendNumber = sendNumber + 1;
  return outputtedSpecs;
}

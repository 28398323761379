import React from "react";
import { Header } from "../Header";
import { MessageList } from "../MessageList";
import { MessageInput } from "../MessageInput";
import { TypingIndicatorDisplay } from "features/typingIndicator/TypingIndicatorDisplay";
import { FlexColumn } from "foundations/components/layout";

const CurrentConversation = () => {
  return (
    <FlexColumn
      height="100%"
      width="100%"
      position={["fixed", "static"]}
      bg="backgrounds.content"
      borderRight="light"
    >
      <div style={{ boxShadow: "rgb(0 0 0 / 13%) 5px 3px 6px" }}>
        <Header />
      </div>
      <MessageList />
      <div style={{ boxShadow: "rgb(0 0 0 / 5%) 0px -5px 10px" }}>
        <MessageInput />
      </div>
      <TypingIndicatorDisplay />
    </FlexColumn>
  );
};

export { CurrentConversation };

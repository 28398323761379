import _ from "lodash";
import datas from "./datas/log_new.json";

var sortedMore = [];

var data = datas;

function sortDataPR(datas) {
  //   console.log("this is data " + datas)
  var sorted = datas.sort(function (a, b) {
    return +a.timeofflight - +b.timeofflight;
  });
  var h = 1;

  _.forEach(sorted, (inside, outside) => {
    h = h + 1;
    var num = [];
    num.push(Number(inside.timeofflight));

    num.push(h);

    data.push(num);
  });
  // console.log("this ran too " + data);
  return data;
}

function sortData(data) {
  var sorted = data.sort(function (a, b) {
    return +a.timeofflight - +b.timeofflight;
  });
  var h = 1;

  _.forEach(sorted, (inside, outside) => {
    h = h + 1;
    sortedMore.push([inside.timeofflight, h]);
  });

  return sortedMore;
}

export { sortData, sortDataPR };

export enum LayoutActionType {
  MENU_VIEW_DISPLAYED = "pubnub/MENU_VIEW_DISPLAYED",
  MENU_VIEW_HIDDEN = "pubnub/MENU_VIEW_HIDDEN",
  CURRENT_CONVERSATION_VIEW_DISPLAYED = "pubnub/CURRENT_CONVERSATION_VIEW_DISPLAYED",
  CURRENT_CONVERSATION_VIEW_HIDDEN = "pubnub/CURRENT_CONVERSATION_VIEW_HIDDEN",
  CONVERSATION_MEMBERS_VIEW_DISPLAYED = "pubnub/CONVERSATION_MEMBERS_VIEW_DISPLAYED",
  CONVERSATION_MEMBERS_VIEW_HIDDEN = "pubnub/CONVERSATION_MEMBERS_VIEW_HIDDEN",
  JOIN_CONVERSATION_VIEW_DISPLAYED = "pubnub/JOIN_CONVERSATION_VIEW_DISPLAYED",
  JOIN_CONVERSATION_VIEW_HIDDEN = "pubnub/JOIN_CONVERSATION_VIEW_HIDDEN"
}

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import * as Survey from "survey-react";
import "survey-react/survey.css";

const FeedbackModalPop = props => {
  const [show, setShow] = useState(false);
  //console.log("it was clicked");
  const handleClose = () => {
    setShow(false);
    if (props.close) {
      props.close();
    }
  };

  Survey.StylesManager.applyTheme("bootstrap");

  var surveyJSON = {
    pages: [
      {
        name: "page1",
        elements: [
          {
            type: "radiogroup",
            name:
              "How did you find out about the Digital Penmanship Application",
            isRequired: true,
            choices: [
              {
                value: "1",
                text: "Verbally from a family member or friend "
              },
              {
                value: "2",
                text: "Facebook Event or Group "
              },
              {
                value: "3",
                text: "Social Media Post"
              },
              {
                value: 4,
                text: "Email"
              },
              {
                value: 5,
                text: "Other"
              }
            ]
          },
          {
            type: "checkbox",
            name:
              "If you feel comfortable disclosing the information, can you tell us which of the chatrooms you participated in?",
            visible: false,
            titleLocation: "hidden",
            choices: [
              {
                value: "1",
                text: "Open"
              },
              {
                value: "2",
                text: "Topic 1"
              },
              {
                value: "3",
                text: "Topic 2"
              }
            ]
          },
          {
            type: "checkbox",
            name: "What social media applications do you belong to?",
            isRequired: true,
            choices: [
              { value: "1", text: "Instagram" },
              { value: "2", text: "TikTok" },
              { value: "3", text: "Facebook" },
              { value: 4, text: "Twitter" },
              { value: 5, text: "Tumblr" },
              { value: 6, text: "Pinterest" },
              { value: 7, text: "Snapchat" },
              { value: 8, text: "Other" }
            ]
          },
          {
            type: "text",
            name:
              "On average how much time, in hours, do you spend on social media each week?",
            isRequired: true,
            inputType: "number",
            min: "0",
            max: "10000"
          },
          {
            type: "ranking",
            name:
              "On which device type do you prefer to access your social media from?",
            title:
              "On which device type do you prefer to access your social media from? (Rank in order of preferred to least preferred by dragging the options listed below). ",
            isRequired: true,
            choices: [
              {
                value: "item1",
                text: "iPad or other tablet device"
              },
              {
                value: "item2",
                text: "Desktop computer or laptop"
              },
              {
                value: "item3",
                text: "Mobile device"
              }
            ]
          },
          {
            type: "radiogroup",
            name:
              "Did you notice that the characters of the font that is being used in the chat application were changing?",
            title:
              "While you were using the application, did you notice that the letters in your message were changing?",
            isRequired: true,
            choices: ["Yes", "No"]
          },
          {
            type: "radiogroup",
            name:
              "Were you able to determine what was causing the characters to vary?",
            visibleIf:
              "{Did you notice that the characters of the font that is being used in the chat application were changing?} = 'Yes'",
            isRequired: true,
            choices: ["Yes", "No"]
          },
          {
            type: "text",
            name: "What do you think was causing the font to change?",
            visibleIf:
              "{Were you able to determine what was causing the characters to vary?} = 'Yes'",
            isRequired: true
          }
        ],
        title: "User Feedback Survey"
      },
      {
        name: "page2",
        elements: [
          {
            type: "html",
            name: "question1",
            html:
              "The font that is being used by the chat application is a variable font that is being altered by your keystroke biometrics. Keystroke biometrics are the unique keystroke patterns that every individual has. Historically, this technology has been used in security technologies to verify and identify who an individual is. Digital Penmanship, is the first application that uses this technology to communicate an individual’s identity, similar to how hand writing does.  "
          },
          {
            type: "rating",
            name:
              "Now that you know what the application is doing, how would you rate the font's overall successfulness at communicating the intent of your message, where 1 is not very successful, and 10 is very successful?",
            isRequired: true,
            rateMax: 10,
            minRateDescription: "Not Very Successful",
            maxRateDescription: "Very Successful"
          },
          {
            type: "rating",
            name:
              "Historically, how likely were you to give careful consideration to the fonts that you used in your own projects?",
            isRequired: true,
            rateMax: 10,
            minRateDescription: "Not Very Likely",
            maxRateDescription: "Very Likely"
          },
          {
            type: "rating",
            name:
              "Historically, how likely were you to spend time thinking about the fonts that were being used in the applications that you used?",
            isRequired: true,
            rateMax: 10,
            minRateDescription: "Not Very Likely",
            maxRateDescription: "Very Likely"
          },
          {
            type: "rating",
            name:
              "After using Digital Penmanship how likely are you to spend time thinking about the fonts that were being used in the applications that you used?",
            isRequired: true,
            rateMax: 10,
            minRateDescription: "Not Very Likely",
            maxRateDescription: "Very Likely"
          },
          {
            type: "rating",
            name:
              "After using Digital Penmanship, how likely are you to spend time thinking about the fonts that are being used in the applications that you use?",
            isRequired: true,
            rateMax: 10,
            minRateDescription: "Not Very Likely",
            maxRateDescription: "Very Likely"
          },
          {
            type: "radiogroup",
            name:
              "Do you think that the varying font added anything to your communication experience?",
            isRequired: true,
            choices: [
              {
                value: "item1",
                text: "Yes"
              },
              {
                value: "item2",
                text: "No"
              },
              {
                value: "item3",
                text: "Unsure"
              }
            ]
          },
          {
            type: "radiogroup",
            name:
              "Did you find that the varying font disrupted your communication experience at all? If yes, how did it disrupt your experience?",
            isRequired: true,
            choices: [
              {
                value: "item1",
                text: "Yes"
              },
              {
                value: "item2",
                text: "No"
              },
              {
                value: "item3",
                text: "Unsure"
              }
            ]
          },
          {
            type: "text",
            name:
              "How did the varying font disrupt your communication experience?",
            visibleIf:
              "{Did you find that the varying font disrupted your communication experience at all? If yes, how did it disrupt your experience?} = 'item1'",
            enableIf:
              "{Did you find that the varying font disrupted your communication experience at all? If yes, how did it disrupt your experience?} = 'item1'"
          },
          {
            type: "rating",
            name:
              "If Digital Penmanship were to be implemented in social media applications like Facebook, Twitter, etc., how likely would you be to use it?",
            isRequired: true,
            rateMax: 10,
            minRateDescription: "Not Very Likely",
            maxRateDescription: "Very Likely"
          },
          {
            type: "text",
            name: "Is there anything else that you would like us to know?",
            isRequired: true
          }
        ]
      }
    ]
  };

  function sendDataToServer(survey) {
    survey.sendResult("ac8048a2-113b-4a43-9935-02d0dca0f9c2");
    if (props.completeSurvey) {
      props.completeSurvey();
    }
  }

  return (
    <>
      <Modal show={show || props.display} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>User Feedback Survey</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Survey.Survey json={surveyJSON} onComplete={sendDataToServer} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export { FeedbackModalPop };

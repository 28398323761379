//----parameters------//

//////////////////////////  mapping variance  ////////////////////////////

function map_variance(value, low1, high1, low2, high2) {
  return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1);
}

function variance_smoother(value, valueTot_avg) {
  return (value + valueTot_avg) / 2;
}

function adj_Parameters(valueTot, ntotal) {
  return valueTot / ntotal;
}

////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////

export { map_variance, variance_smoother, adj_Parameters };

import React, { useContext } from "react";
import { MessageType } from "../messageModel";
import { DraftTextMessage, isDraftModified } from "../draft";
import { useMediaQuery } from "foundations/hooks/useMediaQuery";
import { ThemeContext } from "styled-components";
import { FlexRow, StyledBox } from "foundations/components/layout";
import { Icon, Icons } from "foundations/components/presentation";
import "../../../styles/style.css";
import "../../../styles/ui.css";
import RichEditor from "../../editor/typeConstructor";
import { print } from "../../../responsive/font-interpolation/file-save";

/**
 * Update the text field on a draft text message by returning a new object if
 * the new text is different than the text in the old object.
 * This is the proper way to do updates to avoid unnecessary rerendering.
 */
const newTextDraft = (
  draft: DraftTextMessage,
  newText: string
): DraftTextMessage => {
  if (draft.text === newText) {
    return draft;
  }
  return {
    type: MessageType.Text,
    senderId: draft.senderId,
    text: newText
  };
};

type TextMessageEditorProps = {
  message: DraftTextMessage;
  sendDraft: (message: DraftTextMessage) => void;
  updateDraft: (message: DraftTextMessage) => void;
};

/**
 * Edit a draft Text Message
 */
export const TextMessageEditor = ({
  message,
  sendDraft,
  updateDraft
}: TextMessageEditorProps) => {
  const theme = useContext(ThemeContext);
  const touch = useMediaQuery(theme.mediaQueries.touch);

  const sendMessage = (text: string) => {
    const draft = newTextDraft(message, text);
    if (isDraftModified(draft)) {
      sendDraft(draft);
      print();
    }
  };

  return (
    <FlexRow padding="2">
      <FlexRow flexGrow={1}>
        <RichEditor sendMessage={sendMessage} />
        {/* <RichEditor readOnly={false}/> */}
      </FlexRow>
    </FlexRow>
  );
};

import * as d3 from "d3";
import _ from "lodash";
import regression from "regression";
import fullreglog from "./data-mapping";
var dataEqs = [];

function renderGraph(dataNew, q) {
  var dataEdit = [];
  var minD = d3.min(dataNew, function (d) {
    return d[0];
  });

  var updater;
  // console.log("this was passed to here 1")
  if (minD < 0) {
    updater = 0 - minD;
  } else {
    updater = 0;
  }

  _.forEach(dataNew, (d, outside) => {
    var dataVal = [];
    dataVal.push(d[1], d[0] + updater);
    dataEdit.push(dataVal);
  });

  if (q > 0) {
    var logReg = regression.logarithmic(dataEdit);

    var logRegEq = logReg.string;

    dataEqs.push({
      log: logRegEq
    });

    return dataEdit;
  }
}

export { renderGraph, dataEqs };

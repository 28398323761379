import React from "react";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import { FlexColumn } from "foundations/components/layout";
import surveyJSON from "../surveyJSON.json";

const Feedback = () => {
  Survey.StylesManager.applyTheme("bootstrap");

  function sendDataToServer(survey) {
    survey.sendResult("ac8048a2-113b-4a43-9935-02d0dca0f9c2");
  }

  return (
    <>
      <FlexColumn
        height="100%"
        width="100%"
        position={["fixed", "static"]}
        bg="backgrounds.content"
        borderRight="light"
        style={{ maxHeight: "100vh", overflowY: "scroll" }}
      >
        <Survey.Survey json={surveyJSON} onComplete={sendDataToServer} />
      </FlexColumn>
    </>
  );
};

export { Feedback };

import keydata from "./keyboard_map.json";
import kData from "./keyPress_score.json";
import _ from "lodash";
import { dict } from "../typing";
import { map_variance } from "../font-interpolation";

let maxSlant = 0;
let minSlant = 0;
const strokes = "";
let averageSlant = 0;
const potOverlap = { left: 0, right: 0 };
const dominanceMapping = { left: 0, right: 0 };
let storeKey = "";
function strokeString(key, press, kScore) {
  // var arr = 0;
  // _.forEach(kData.key_Pressed, (inside, outside) => {
  //   arr = arr + inside;
  // });
  //console.log("arr value " + arr);
  // var lnum = strokes.length;
  if (press === 1) {
    storeKey = key;
  } else {
    const key1 = storeKey;
    //console.log(key1);
    const key2 = key;
    // console.log(keydata.key_location[key2]);
    const key1Side = keydata.key_location[key1];
    // console.log(key1Side);
    const key2Side = keydata.key_location[key2];

    if (key1Side === key2Side && key1 !== key2) {
      const side = keydata.key1;
      // console.log(side);

      potOverlap[key1Side]++;

      if (kScore >= 2) {
        dominanceMapping[key1Side]++;
      }
      // console.log(potOverlap);
      // console.log(key1Side + " has had " + dominanceMapping[key1Side] + " actual overlaps");
    }

    const leftMap = dominanceMapping.left / potOverlap.left;
    //  console.log(leftMap);
    const rightMap = dominanceMapping.right / potOverlap.right;

    //  console.log(rightMap);

    var proportion = 0 + rightMap - leftMap;

    // console.log(proportion);
    storeKey = key;
  }
  let slant = map_variance(proportion, -1, 1, -15, 15);
  if (slant === undefined || slant === null || isNaN(slant)) {
    slant = 0;
  }

  if (slant < maxSlant && slant < minSlant) {
    minSlant = slant;
  } else if (slant > maxSlant && slant > minSlant) {
    maxSlant = slant;
  }

  averageSlant = (averageSlant * (press - 1) + slant) / press;

  return slant;
}
export { strokeString, minSlant, maxSlant, averageSlant };

import React from "react";
import ReactDOM from "react-dom";
import "./styles/style.css";
import "./styles/ui.css";
import ReactGA from "react-ga";

import { App } from "./main/App";
import * as serviceWorker from "./main/serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

ReactGA.initialize("UA-148429351-2"); // add your tracking id here.
ReactGA.pageview(window.location.pathname + window.location.search);

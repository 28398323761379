import { map_variance } from "../font-interpolation";
import force from "../avg_force.json";
import * as math from "mathjs";
import { fullLog } from "../typing";

//core file
//accerleration = delta v / t; [change in velocity over elapsed time];
//force = mass * acceleration
//mass is a constant in this instance, so force increases as acceleration increases

//

// //----parameters------//

const local = 20;
const average = 40;
const independent = 40;

let storedPress;
let speed = 0; //speed of typing
const total = 0; //total number of characters that have been typed

let curve;
let weight = 250;
const rateList = [0];
const accList = [0];
const pressTmList = [0];
let pressController = 0;
let lapseTime;
let startValue;
// ////////////////////////////   weight mapping   ////////////////////////////

//creating sample data to preload in the system to minimize excessively weighted font
//average typing speed for people 50 to 80 wpm
//average length of a word in the english language is 4.7 characters
//thus the average typing speed is
//235 - 376 characters per second

//sample data
// var sampleData = [0, 235, 376, 100, 220, 210, 300, 310, 334]
// ////////////////////////////   Lag Time Controller   ////////////////////////////
// used to control what keys are being considered when calculating acceleration—this uses a maximum of 50 keys. After 50 keys it resets the start time

//
function startTimeReg(pressTime, press, pressLog) {
  // temp_lapcTime

  if (pressController === 1) {
    startValue = pressTime;
    pressController++;
    return startValue;
  } else if (pressController < 50) {
    pressController++;

    const temp_lapCheck = lapseChecker(startValue, pressLog[press]);
    if (temp_lapCheck === "true") {
      startValue = pressTime;
      pressController = 2;
    }
    return startValue;
  } else if (pressController === 50) {
    //   var dnew = new Date();
    startValue = pressTime;
    pressController = 2;
    return startValue;
  }
}

////////////////////////////////////////////////////////////////////////////////////
// ////////////////////////////   Lapse Time Checker   ////////////////////////////

function lapseChecker(intStart, currentTime) {
  // let temp_lapcTime = dnew.getTime();
  lapseTime = (currentTime - intStart) / 1000;

  if (lapseTime > 15) {
    return "true";
  } else {
    return "false";
  }
}

////////////////////////////////////////////////////////////////////////////////////
// ////////////////////////////  Calculate Elapsed Time   ////////////////////////////

function elapseTm(startTime, currentTime) {
  const elapseTime = currentTime - startTime;
  const elapseTimeSec = elapseTime / 1000;
  return elapseTimeSec;
}

////////////////////////////////////////////////////////////////////////////////////
// ////////////////////////////   Null Value Catcher  ////////////////////////////

function nullCheck(value) {
  if (value === undefined || value === null || isNaN(value)) {
    return true;
  } else {
    return false;
  }
}

////////////////////////////////////////////////////////////////////////////////////
// ////////////////////////////  Sort a List of Items   ////////////////////////////
// function tempList(input) {
//   let tempRList = input.sort((a, b) => a - b); // Sort numerically, ascending

//   return tempRList;
// }

////////////////////////////////////////////////////////////////////////////////////
// ////////////////////////////     Calculate Rate      ////////////////////////////

function rateCalc(elapseTm, press) {
  if (elapseTm === 0) {
    speed = 0;
  } else {
    speed = press / elapseTm;
    if (speed === undefined || speed === null || isNaN(speed)) {
      speed = 0;
    } else {
      if (rateList[0] === 0) {
        rateList.shift();
      }
      rateList.push(speed);
    }
  }
  return speed;
}

////////////////////////////////////////////////////////////////////////////////////
// //////////////////////////       Mapping Values      ////////////////////////////
function mapFactor(data, value, min_value, max_value) {
  let sum = 0; // stores sum of elements
  let sumsq = 0; // stores sum of squares
  for (var i = 0; i < data.length; ++i) {
    sum += data[i];
    sumsq += data[i] * data[i];
  }
  const l = data.length;
  const mean = math.mean(data);
  const varience = sumsq / l - mean * mean;
  const sd = Math.sqrt(varience);
  const data3 = []; // uses for data which is 3 standard deviations from the mean
  for (var i = 0; i < data.length; ++i) {
    if (data[i] > mean - 3 * sd && data[i] < mean + 3 * sd) data3.push(data[i]);
  }

  // let temp_list = tempList(list_item);
  const minimum = Math.min.apply(null, data3);
  const maximum = Math.max.apply(null, data3);

  if (value > maximum) {
    const varItems = [value, maximum];
    const variation = dataVariability(varItems);
    return {
      0: 1,
      1: maximum
    };
  } else if (value < minimum) {
    const varItems = [value, minimum];
    const variation = dataVariability(varItems);
    return {
      0: 2,
      1: maximum
    };
  } else {
    const temp_mappedValue = map_variance(
      value,
      minimum,
      maximum,
      min_value,
      max_value
    );

    return temp_mappedValue;
  }
}

////////////////////////////////////////////////////////////////////////////////////
// //////////////    Determine how varaible a set of data is   ////////////////////

function dataVariability(data) {
  const varScore = math.variance(data);
  // console.log("varScore is equal to " + varScore);

  return varScore;
}

////////////////////////////////////////////////////////////////////////////////////
// //////////////////////////     Log the Variability      /////////////////////////

const variability_log = [0];

////////////////////////////////////////////////////////////////////////////////////
// ////////////////////////     Calculate Local Speed     /////////////////////////

function keyAvgSpeed(key, value) {
  //Create a log of temporary local variability values
  let temp_localVariability = [0];
  let newAvgValue = 0;

  //if the value for the avg speed is equal to 0 then just set the variance to O and log the current speed to the average speed
  if (force.avg_force[key].avgSpeed === 0) {
    // newAvgValue = value;
    force.avg_force[key].avgSpeed = value;
    temp_localVariability = 0;

    //if the value for the average speed isn't 0 then do this.
  } else {
    //Old value is the average speed going into this keypress instance
    const old_value = force.avg_force[key].avgSpeed;

    //the temporary points to compare for variability are the old_value and the current value
    const temp_keyPoints = [old_value, value];

    //calculate the variability
    temp_localVariability = dataVariability(temp_keyPoints);

    //set the total number of keys that have bene pressed so that we can use it to reverse the
    //average and determine our new average value

    const old_total = force.avg_force[key].tot;
    const old_compute = old_value * old_total;

    //the new average value is calculated
    newAvgValue = (old_compute + value) / (old_total + 1);

    //set the new average speed as the value for avgSpeed in our list
    force.avg_force[key].avgSpeed = newAvgValue;

    //add one value to the total number of times that key was pressed
    force.avg_force[key].tot++;

    //control for undefined values to prevent errors
    if (
      temp_localVariability === undefined ||
      temp_localVariability === null ||
      isNaN(temp_localVariability)
    ) {
      //if the value is null then return the value as 0
      temp_localVariability = 0;
    }

    variability_log.push(temp_localVariability);
  }

  //return the variance value
  return temp_localVariability;
}

////////////////////////////////////////////////////////////////////////////////////
// /////////////////    Calculate Local Acceleration Speed     ////////////////////

const variability_accLog = [0];

function keyAvgAcc(key, value) {
  //Create a log of temporary local variability values
  let temp_localVariability = [0];
  //set the new average value to 0;
  let newAvgValue = 0;

  //if the value for the avg speed is equal to 0 then just set the variance to O and log the current speed to the average speed
  if (force.avg_force[key].avg === 0) {
    // newAvgValue = value;
    force.avg_force[key].avg = value;
    temp_localVariability = 0;
    //console.log(force.avg_force[key.avgSpeed]);

    //if the value for the average speed isn't 0 then do this.
  } else {
    //Old value is the average speed going into this keypress instance
    const old_value = force.avg_force[key].avg;

    //the temporary points to compare for variability are the old_value and the current value
    const temp_keyPoints = [old_value, value];

    //calculate the variability
    temp_localVariability = dataVariability(temp_keyPoints);

    //set the total number of keys that have bene pressed so that we can use it to reverse the
    //average and determine our new average value

    const old_total = force.avg_force[key].tot;
    const old_compute = old_value * old_total;

    //the new average value is calculated
    newAvgValue = (old_compute + value) / (old_total + 1);

    //set the new average speed as the value for avgSpeed in our list
    force.avg_force[key].avg = newAvgValue;

    //add one value to the total number of times that key was pressed
    force.avg_force[key].tot++;

    //control for undefined values to prevent errors
    if (
      temp_localVariability === undefined ||
      temp_localVariability === null ||
      isNaN(temp_localVariability)
    ) {
      //if the value is null then return the value as 0
      temp_localVariability = 0;
    }

    // console.log('the key variability is ' + temp_localVariability)
    variability_accLog.push(temp_localVariability);
  }

  //return the variance value
  return temp_localVariability;
}

////////////////////////////////////////////////////////////////////////////////////
//////////////////     Calculate Local Acceleration Value       ///////////////////

function accelerationCalc(speed, storeVelocity, currentTime, pastTime) {
  //temp elapse value is equal to the past time value and the current time value
  const temp_elapse = elapseTm(pastTime, currentTime);

  //temp elapse value is equal to the past time value and the current time value
  if (temp_elapse === 0) {
    // eslint-disable-next-line
    let acceleration = 0;
  } else {
    // console.log(temp_elapse)
    let acceleration = (speed - storeVelocity) / temp_elapse;

    // console.log(acceleration);

    // console.log("t is equal to " + t);
    if (
      acceleration === undefined ||
      acceleration === null ||
      isNaN(acceleration)
    ) {
      acceleration = 0;
    } else {
      //push to acceleration list
      accList.push(acceleration);
    }
    // console.log(accList);

    //return the acceleration value
    return acceleration;
  }
}

////////////////////////////////////////////////////////////////////////////////////
///////////////////////      Calculate Map Check Value      ///////////////////////

function mapCheck(s, b_one, b_two, data) {
  let sum = 0; // stores sum of elements
  let sumsq = 0; // stores sum of squares
  for (var i = 0; i < data.length; ++i) {
    sum += data[i];
    sumsq += data[i] * data[i];
  }
  const l = data.length;
  const mean = math.mean(data);
  const varience = sumsq / l - mean * mean;
  const sd = Math.sqrt(varience);
  const data3 = []; // uses for data which is 3 standard deviations from the mean
  for (var i = 0; i < data.length; ++i) {
    if (data[i] > mean - 3 * sd && data[i] < mean + 3 * sd) data3.push(data[i]);
  }

  //set the minimum and maximum values for mapping
  const a_one = Math.min(data3);
  const a_two = Math.max(data3);

  //comput the mapping
  const t = b_one + ((s - a_one) * (b_two - b_one)) / (a_two - a_one);

  if (t === undefined || t === null || isNaN(t)) {
  } else {
    return t;
  }
}

const force_log = [0];
const force_checker = [0];

function forceVariance(acceleration, key, press) {
  //the
  const pWeight = force.avg_force[key];

  //Impulse = Change in momentum
  //The physics of collisions are governed by the laws of momentum
  //impulse-momentum change equation
  //In a collision, an object experiences a force for a specific amount of time that results
  //in a change in momentum. The result of the force acting for the given amount of time is that
  //the object's mass either speeds up or slows down (or changes direction). The impulse experienced
  //by the object equals the change in momentum of the object. In equation form, F • t = m • Δ v.

  //In a collision, objects experience an impulse; the impulse causes and is equal to the change in momentum.

  //Rebound Effect
  //the greater the rebound effect, the greater the acceleration, momentum change, and impulse
  // A rebound is a special type of collision involving a direction change in addition to a speed change.
  // The result of the direction change is a large velocity change.
  //On occasions in a rebound collision, an object will maintain the same or nearly the same
  //speed as it had before the collision. Collisions in which objects rebound with the same speed (and thus, the same
  //momentum and kinetic energy) as they had prior to the collision are known as elastic collisions
  //In general, elastic collisions are characterized by a large velocity change, a large momentum change,
  //a large impulse, and a large force.

  var variance;
  if (pWeight.avg === 0) {
    variance = 0;
  } else {
    const aVar = (acceleration - pWeight.avg) / pWeight.avg;
    if (aVar === undefined || aVar === null || isNaN(aVar) || aVar === 0) {
      variance = 0;
    } else {
      force_log.push(aVar);

      if (press > 30) {
        const variance_mapping = mapFactor(force_log, aVar, 0, 1);
        // var variance_mapping = mapFactor(force_log, aVar, 0, 1);
        if (
          variance_mapping === undefined ||
          variance_mapping === null ||
          isNaN(variance_mapping) ||
          variance_mapping === 0
        ) {
          variance = 0;
        } else {
          force_checker.push(variance_mapping);
          var variance = mapFactor(force_checker, variance_mapping, 0, 20);
          if (
            variance === undefined ||
            variance === null ||
            isNaN(variance) ||
            variance === 0
          ) {
            variance = 0;
          }
        }
      }
    }
  }

  return variance;
}

let maxSpeed = 0;
let minSpeed = 0;
const speedChecker = [];
const currentValues = [];
const accelChecker = [];
const speedVariance = [];
const accInSpeedLog = [];
let curveVal;
let specs = "";
function compWeight(key, pressTime, press, pressLog) {
  const currentTime = pressTime;
  const temp_dStart = startTimeReg(pressTime, press, pressLog);
  pressTmList.push(currentTime);

  if (force.avg_force[key]) {
    //Only run if press is greater than one to prevent there being an error returned

    if (press > 1) {
      let pFactor = 0;
      let temp_elapseTmSpeed = 0;
      //calculate your elapse time to help to determine speed.
      if (press === 2) {
        //what is the current rate that the user is typing using the last 2 keypresses
        temp_elapseTmSpeed = elapseTm(temp_dStart, currentTime);

        //value to tell the elapse calculator how many keystrokes are being considered
        pFactor = 2;
      } else {
        //what is the current rate that the user is typing using the last 3 keypresses
        temp_elapseTmSpeed = elapseTm(pressLog[press - 2], currentTime);

        //value to tell the elapse calculator how many keystrokes are being considered
        pFactor = 3;
      }

      //calculate the rate using the ellapse time for the last 3 keypress
      const temp_speed = rateCalc(temp_elapseTmSpeed, pFactor);
      //set the current stored velocity to the last item right before this one in rateList
      const storeVelocity = rateList[rateList.length - 2];

      //speed factor calculations 1o0% of 40% of weight
      //add the current speed to our log to be outputted when the user sends their message later on
      force.avg_force[key].key_speeds.push(temp_speed);

      //calculate the average speed using the math.js library
      const temp_avg_Speed = math.mean(rateList);

      const temp_speedParemeters = [temp_speed, temp_avg_Speed];

      const temp_speedVariance = dataVariability(temp_speedParemeters);

      speedVariance.push(temp_speedVariance);
      // console.log(rateList);

      const temp_speedVarFactor = mapFactor(
        speedVariance,
        temp_speedVariance,
        0,
        independent
      );
      if ((temp_speedVarFactor[0] === 1) | (temp_speedVarFactor[0] === 2)) {
        //bypass down to the total
        weight = 250;
      } else {
        //determine how variable the current speed is compared to the average speed for the specific key in question
        const temp_keySpeedAvg = keyAvgSpeed(key, temp_speed);
        // console.log("key speed average is equal to " + temp_keySpeedAvg);

        //map our avg speed variance linearly onto the range 0 to 40
        if ((temp_speedVarFactor[0] === 1) | (temp_speedVarFactor[0] === 2)) {
          weight = 500;
          //bypass down to the total
        } else {
          const dupRate = rateList;
          const temp_avgSpeedFactor = mapFactor(
            dupRate,
            temp_avg_Speed,
            0,
            average
          );
          if ((temp_speedVarFactor === 1) | (temp_speedVarFactor === 2)) {
            //bypass down to the total
          } else {
            const temporary_list = [1, 16.92];
            //map our speed linearly onto the range 0 to 40
            const temp_speedFactor = mapFactor(
              temporary_list,
              temp_speed,
              0,
              independent
            );

            //map our local key speed variance linearly onto the range 0 to 20
            const temp_keySpeedFactor = mapFactor(
              variability_log,
              temp_keySpeedAvg,
              0,
              local
            );

            //our total speed score is equal to speed factor + local speed vaiance + avg speed factor
            //the maximum value the total can be is 100, this value is then mapped onto a range of 0 to 40
            let temp_speedScore;
            let spMax;

            if (press < 30) {
              temp_speedScore = temp_speedFactor + temp_keySpeedFactor;
              spMax = local + independent;
            } else {
              temp_speedScore =
                temp_speedFactor + temp_keySpeedFactor + temp_avgSpeedFactor;
              spMax = 100;
            }

            //map the total speed factor onto a range of 0 to 40 because it makes up 40% of the end total
            const temp_speedTotFactor = map_variance(
              temp_speedScore,
              0,
              spMax,
              0,
              20
            );

            //avg acceleration factor calculations 70% of 40% of weight
            const temp_pastPressSTime = pressTmList[pressTmList.length - 2];
            const temp_acceleration = accelerationCalc(
              temp_speed,
              storeVelocity,
              currentTime,
              storedPress
            );
            let accInSpeed = 0;
            const accLapse = (currentTime - storedPress) / 1000;
            if (accLapse > 2) {
              accInSpeed = accInSpeedLog[accInSpeedLog.length - 1];
            } else {
              accInSpeed = 1 / accLapse;
            }

            accInSpeedLog.push(accInSpeed);

            // console.log(accInSpeedLog);

            const weight_two = mapFactor(accInSpeedLog, accInSpeed, 0, 100);

            // console.log(weight_two);

            // let weight_two = map_variance(accInSpeed, 0, 1, 0, 100);

            //force.avg_force[key].key_acc.push(temp_acceleration);
            const temp_avg_Acceleration = math.mean(accList);
            const temp_avgAcc = mapFactor(
              accList,
              temp_avg_Acceleration,
              0,
              20
            );
            if ((temp_avgAcc[0] === 1) | (temp_avgAcc[0] === 2)) {
              //bypass down to the total
            } else {
              const temp_keyAccAvg = keyAvgAcc(key, temp_acceleration);

              const temp_accFactor = mapFactor(
                variability_accLog,
                temp_acceleration,
                0,
                20
              );

              const temp_keyAccAvgFactor = mapFactor(
                variability_accLog,
                temp_keyAccAvg,
                0,
                30
              );

              const temp_accelerationFactor = mapFactor(
                accList,
                temp_acceleration,
                0,
                100
              );

              let temp_accelerationScore;

              if (press < 30) {
                temp_accelerationScore =
                  temp_keyAccAvgFactor + temp_accelerationFactor;
              } else {
                temp_accelerationScore =
                  temp_keyAccAvgFactor + temp_accelerationFactor + temp_avgAcc;
              }

              const accelerationValues = {
                keyAvg: temp_keyAccAvgFactor,
                acceleration: temp_accelerationFactor,
                average: temp_avgAcc,
                record: force.avg_force[key].key_acc
              };

              const temp_accTotFactor = map_variance(
                temp_accelerationScore,
                0,
                spMax,
                0,
                40
              );
              const temp_force_Variation = forceVariance(
                temp_acceleration,
                key,
                press
              );
              // let temp_force_Variation = forceLocal(key, temp_acceleration, press);

              let temp_factorTotal;
              let totControl;
              if (press < 30) {
                temp_factorTotal = temp_accTotFactor + temp_speedTotFactor;
                totControl = 80;
                curveVal = 0;
              } else {
                temp_factorTotal =
                  temp_accTotFactor +
                  temp_speedTotFactor +
                  temp_force_Variation;
                totControl = 100;
                curveVal = map_variance(temp_speedScore, 0, 80, 0, 1);
              }

              const temp_inverse = 100 - temp_factorTotal;
              const temp_weightValue = map_variance(
                100 - weight_two,
                0,
                totControl,
                200,
                700
              );

              fullLog[press].velocity = storeVelocity;
              fullLog[press].speed = temp_speed;
              fullLog[press].averageSpeed = temp_avg_Speed;
              fullLog[press].keySpeedVar = temp_keySpeedAvg;
              fullLog[press].avg_Sp_Factor = temp_avgSpeedFactor;
              fullLog[press].speed_factor = temp_speedVarFactor;
              fullLog[press].key_Sp_Factor = temp_keySpeedFactor;
              fullLog[press].speed_TOT_Score = temp_speedTotFactor;
              fullLog[press].acceleration = temp_acceleration;
              fullLog[press].avg_acceleration = temp_avg_Acceleration;
              fullLog[press].key_avg_acceleration = temp_keyAccAvg;
              fullLog[press].key_ACC_Factor = temp_keyAccAvgFactor;
              fullLog[press].accel_Factor = temp_accelerationFactor;
              fullLog[press].acceleration_Factor = temp_accelerationScore;
              fullLog[press].acc_TOT_Score = temp_accTotFactor;
              fullLog[press].force_variance_Factor = temp_force_Variation;
              fullLog[press].total_Weight_Factor = temp_factorTotal;

              maxSpeed = math.max(rateList);
              minSpeed = math.min(rateList);
              specs =
                "Max Typing Speed: " +
                Math.round(maxSpeed * 10) / 10 +
                "\n Min Typing Speed: " +
                Math.round(minSpeed * 10) / 10 +
                "\n Average Typing Speed: " +
                Math.round(temp_avg_Speed * 10) / 10;

              // console.log(temp_avg_Speed);
              // console.log(maxSpeed, minSpeed);
              // console.log(specs);
              // console.log(accInSpeedLog);
              const nanChecker = nullCheck(temp_weightValue);

              if (nanChecker === true) {
              } else {
                weight = temp_weightValue;
              }
            }
          }
        }
      }

      if (curveVal === undefined || curveVal === null || isNaN(curveVal)) {
        curveVal = 0;
      }
      curve = curveVal;
    }
  } else {
  }
  storedPress = currentTime;

  // console.log(weight);
  return {
    0: weight,
    1: curve
  };
}

function compCurve(key) {}
const dwellTimeValues = [];
function dwellTm(pressNo, key, startTime, releaseTime) {
  if (force.avg_force[key]) {
    const beginPress = startTime;

    const dwellTime = releaseTime / 1000 - beginPress / 1000;
    force.avg_force[key].dwellTm = dwellTime;

    if (total < 1) {
      force.avg_force[key].avgDT = dwellTime;

      fullLog[pressNo].cur_dwellTime = dwellTime;
      fullLog[pressNo].avg_dwellTime = "n/a";
      fullLog[pressNo].var_dwellTime = "n/a";
    } else {
      const avgDT = force.avg_force[key].avgDT;
      force.avg_force[key].avgDT = (avgDT * total + dwellTime) / (total + 1);
      let varDT = (dwellTime - avgDT) / avgDT;

      if (varDT >= 1.5) {
      }
      if (varDT <= -1.5) {
      } else {
        varDT = 1;
      }
      return varDT;
      // eslint-disable-next-line
      fullLog[pressNo].cur_dwellTime = dwellTime;
      fullLog[pressNo].avg_dwellTime = avgDT;
      fullLog[pressNo].var_dwellTime = varDT;
    }
  }
}

const vScoreList = [];
const invVScoreList = [];
const rateVarianceValues = [];
function patVariance() {
  const varScore = math.variance(rateList);
  vScoreList.push(varScore);

  const temp_vMono = mapFactor(vScoreList, varScore, 0, 100);

  const temp_vMonoFactor = map_variance(temp_vMono, 0, 100, 0, 60);
  // console.log("global mono = " + temp_vMonoFactor);

  const temp_vMonoTOTAL = addArrayNums(vScoreList);

  const temp_vMonoAVG = temp_vMonoTOTAL / vScoreList.length;
  const temp_vMonoMAPAVG = mapFactor(vScoreList, temp_vMonoAVG, 0, 100);
  const temp_vMonoAVGFactor = map_variance(temp_vMonoMAPAVG, 0, 100, 0, 40);
  const temp_vMonoVal = temp_vMonoFactor + temp_vMonoAVGFactor;

  const temp_mapMonoOutput = map_variance(temp_vMonoVal, 0, 100, 0, 100);
  // console.log(temp_vMonoFactor + temp_vMonoVal);
  const temp_monoValue = 100 - temp_mapMonoOutput;

  //console.log("local mono = " + temp_vMonoAVGFactor);

  //console.log("inverse mono = " + temp_monoValue);

  // rateVarianceValues.push({
  //   "rate list variance": varScore,
  //   "mapped rate variance from 0-100": temp_vMono,
  //   "mapped rate variance comp factor": temp_vMonoFactor,
  //   "overall average variance scores": temp_vMonoAVG,
  //   "mapped average variance scores 0-100": temp_vMonoMAPAVG,
  //   "outputted mono spacing": temp_vMonoVal,
  //   "0 to 100 controller incase of outlier": temp_mapMonoOutput
  // });

  const temp_monoValue_mapped = map_variance(temp_monoValue, 0, 100, 0, 1);

  return temp_monoValue_mapped;
}

function addArrayNums(arr) {
  let total = 0;
  for (let i = 0; i < arr.length - 1; i++) {
    if (i === 0) {
      if (isNaN(arr[i])) {
        arr[i] = 0;
      }
      total = arr[i];
    } else {
      total = arr[i] + total;
    }
  }
  return total;
}
// //////////////////////////// end weight mapping ////////////////////////////

export {
  compWeight,
  dwellTm,
  maxSpeed,
  minSpeed,
  rateList,
  compCurve,
  patVariance,
  mapFactor,
  invVScoreList,
  currentValues,
  dwellTimeValues,
  rateVarianceValues
};

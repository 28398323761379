import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { appTheme } from "../../../main/Theme";
import GlobalStyles from "../../../main/styles/GlobalStyles";
import DOMPurify from "dompurify";

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: "none"
  },
  paper: {
    padding: theme.spacing(1)
  }
}));

export default function DataPopover(props) {
  const filler = props.content;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  //   const handlePopoverOpen = event => {
  //     setAnchorEl(event.currentTarget);
  //   };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(props.anchorEl);

  return (
    <Popover
      id="mouse-over-popover"
      className={classes.popover}
      classes={{
        paper: classes.paper
      }}
      open={open}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Typography
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(filler) }}
      ></Typography>
    </Popover>
  );
}

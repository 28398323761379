import React from "react";
// eslint-disable-next-line
import { TextMessage as TextMessageModel } from "../messageModel";
import { TextMessage } from "foundations/components/chat";
/**
 * Display a TextMessage such as it would appear in a list of messages
 */

export const TextMessageDisplay = ({ message, parentKey }) => {
  // eslint-disable-next-line
  const formatString = text => {
    const parser = new DOMParser();
    return parser.parseFromString(text, "text/html");
  };

  return (
    <>
      <TextMessage text={message.text} />
    </>
  );
};

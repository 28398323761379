// theme based on the system-ui specification https://github.com/system-ui/theme-specification

// Breakpoints
const breakpoints = ["600px", "1200px"] as const;
const mediaQueries = {
  medium: `@media screen and (min-width: ${breakpoints[0]})`,
  large: `@media screen and (min-width: ${breakpoints[1]})`,
  touch: `@media (hover: none)`
} as const;

// Fonts
const fonts = {
  app: '"Noboto Flex"',
  appOff: '"Helvetica"'
} as const;
const fontSizes = [
  "0px",
  "11px",
  "12px",
  "13px",
  "15px",
  "20px",
  "24px"
] as const;
const fontSizesAliases = {
  none: fontSizes[0],
  small: fontSizes[1],
  card: fontSizes[2],
  regular: fontSizes[3],
  medium: fontSizes[4],
  large: fontSizes[5],
  huge: fontSizes[6]
};
const fontWeights = [0, 300, 400, 500, 700, 900] as const;
const fontWeightsAliases = {
  none: fontWeights[0],
  light: fontWeights[1],
  regular: fontWeights[2],
  medium: fontWeights[3],
  bold: fontWeights[4],
  black: fontWeights[5]
};

// Colors
const colors = {
  error: "#F79B77",
  success: "#bee6a8;",
  inactive: "#4B4345",
  primary: ["#ffffff", "#fafafa", "#CCE8E3"],
  neutral: ["#000000", "#FAFAFA", "#F4F4F4", "#FFF"],
  accent: ["#7E5A7E", "#523C52", "#E6F2F0"],
  avatars: ["#edd385", "#9be0d8", "#bba3c2", "#e09faa"]
} as const;

const backgrounds = {
  primary: colors.primary[2],
  login: colors.neutral[3],
  primaryHover: colors.accent[2],
  primaryActive: "rgba(0, 0, 0, 0.1)",
  panel: colors.primary[1],
  panelHover: colors.accent[2],
  content: colors.neutral[3],
  contentHover: colors.accent[2],
  message: colors.neutral[2]
};

const colorAliases = {
  onPrimary: colors.neutral[0],
  selectedText: colors.accent[0],
  active: colors.accent[0],
  activeText: colors.accent[0],
  messageText: colors.neutral[0],
  normalText: colors.neutral[0],
  importantText: colors.neutral[0],
  avatarText: colors.neutral[0],
  borderLight: colors.accent[0],
  borderDark: colors.accent[0],
  backgrounds: backgrounds
} as const;

// Spacing & sizing
const sizes = ["0", "36px", "56px", "85px", "260px", "290px"] as const;
const space = [
  "0",
  "10px",
  "12px",
  "14px",
  "16px",
  "21px",
  "24px",
  "32px",
  "40px",
  "5%"
] as const;

// Borders
const radii = ["0", "5px", "10px", "100vmax"] as const;
const radiiAliases = {
  square: radii[0],
  light: radii[1],
  messageEditor: radii[1],
  medium: radii[2],
  strong: radii[2],
  round: radii[3]
};
const borderWidths = ["0", "1px", "5px"] as const;
const borderStyles = ["solid"] as const;
const borders = [
  "none",
  `${borderWidths[1]} ${borderStyles[0]} ${colorAliases.borderLight}`,
  `${borderWidths[2]} ${borderStyles[0]}`,
  `${borderWidths[1]} ${borderStyles[0]} ${colorAliases.borderDark}`,
  `${borderWidths[1]} ${borderStyles[0]} ${colors.neutral[1]}`
] as const;
const bordersAliases = {
  none: borders[0],
  light: borders[1],
  strong: borders[2],
  dark: borders[3],
  medium: borders[4]
};

// Shadows
const shadows = [
  "0 6px 10px rgba(103, 19, 176, 0.06)",
  "0px 6px 10px rgba(103, 19, 176, 0.2)",
  "0 4px 30px rgba(0, 0, 0, 0.4)",
  "4pt 2pt 13pt #2f2f2f2e",
  "3px 0px 19px 12px rgb(209 219 220 / 20%)",
  "1px 0px 6px #00000021"
] as const;

// Custom
const custom = {
  dark: false,
  companyName: "Digital Penmanship",
  tagLine: ""
} as const;

// Exports
export const appTheme = {
  breakpoints,
  mediaQueries,
  fonts,
  fontSizes: { ...fontSizes, ...fontSizesAliases },
  fontWeights: { ...fontWeights, ...fontWeightsAliases },
  colors: { ...colors, ...colorAliases },
  backgrounds,
  sizes,
  space,
  radii: { ...radii, ...radiiAliases },
  borderWidths,
  borderStyles,
  borders: { ...borders, ...bordersAliases },
  shadows,
  custom
};

export type Theme = typeof appTheme;

import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Survey from "survey-react";
import "survey-react/survey.css";

const ErrorModal = props => {
  const [show, setShow] = useState(false);
  //console.log("it was clicked");
  const handleClose = () => {
    setShow(false);
    if (props.close) {
      props.close();
    }
  };
  const handleShow = () => setShow(true);

  Survey.StylesManager.applyTheme("bootstrap");

  const buttonCode = [];

  if (!props.hideButton) {
    buttonCode.push(
      <Button id="formTrigger" variant="primary" onClick={handleShow}>
        Feedback
      </Button>
    );
  }

  return (
    <>
      {buttonCode}
      <Modal show={show || props.display} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Oh Snap! Something didn't work quite right!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          We are having some difficulties sending your message. This could be
          because of the length of your message. Try shortening your message and
          resending it. If the problem persists please contact the site creator,
          Arielle Cerini, at cerina@rpi.edu, or via the Facebook page. We
          apologize for the difficulty, and want to thank you for participating
          in today's user testing. Happy Typing!
        </Modal.Body>
      </Modal>
    </>
  );
};

export { ErrorModal };

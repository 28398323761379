import * as uuid from "uuid";
import axios from "axios";
import "../../features/api/user-data.json";
import { fullLog } from "../typing";

var userID;
// const uuidv4 = require("uuid/v4")

function print() {
  // "use strict";
  if (userID > 0) {
  } else {
    userID = uuid.v4();
  }
  // var joined = [];
  // joined.push({
  //   "user id": userID,
  //   "stroke log data": strokeLog,
  //   "weight mapping data": currentValues,
  //   "dwell time variance values": dwellTimeValues,
  //   "rate variance values": rateVarianceValues,
  //   "data for plotting": fullregCompLog
  // });

  axios
    .post("https://ariellecerini.com/api/userDispatch", {
      name: userID,
      data: fullLog
    })
    .then(data => {
      // console.log(data);
    })
    .catch(error => {
      // console.error(error);
    });

  //   const app = new Realm.App({ id: "digitalpenmanship-jwgel" });
  // const credentials = Realm.Credentials.anonymous();
  // try {
  //   const user = await app.logIn(credentials);
  // } catch(err) {
  //   console.error("Failed to log in", err);
  // }
  //   const fs = require("fs");
  //   var d = new Date();
  //   d.setMonth(d.getMonth() + 1);

  //   let data = JSON.stringify(joined);
  //   var fName =
  //     d.getFullYear() +
  //     "_" +
  //     d.getMonth() +
  //     "M_" +
  //     d.getDate() +
  //     "D_" +
  //     d.getHours() +
  //     "H_" +
  //     d.getMinutes() +
  //     "m_" +
  //     d.getSeconds() +
  //     "s";
  //   fs.writeFileSync(userID + fName + ".json", data);

  // currentValues = [];
  // dwellTimeValues = [];
  // fullregCompLog = [];
  // strokeLog = [];
  // rateVarianceValues = [];
}

export { print };

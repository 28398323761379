import React from "react";

import {
  Editor,
  // eslint-disable-next-line
  EditorState,
  convertToRaw,
  // eslint-disable-next-line
  ContentState,
  // eslint-disable-next-line
  RichUtils,
  // eslint-disable-next-line
  AtomicBlockUtils,
  // eslint-disable-next-line
  ContentBlock,
  // eslint-disable-next-line
  CharacterMetadata,
  // eslint-disable-next-line
  getDefaultKeyBinding
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import createStyles from "draft-js-custom-styles";
import { typing, typeRelease } from "../../responsive/typing";
// eslint-disable-next-line
import DOMPurify from "dompurify";
import { clearEditorContent } from "draftjs-utils";
import "draft-js/dist/Draft.css";
import { FeedbackModalPop } from "../bootstrap/feedback-modal-pop/FeedbackModalPop";
import { ErrorModal } from "../bootstrap/error-modal/ErrorModal";

import { Button } from "react-bootstrap";
import getSpecData from "responsive/getSpecData";

var caseNumber = 0;

var recordNo = 0;

const customStyleMap = {
  MARK: {
    backgroundColor: "Yellow",
    fontStyle: "italic"
  }
};

// Passing the customStyleMap is optional
const { styles, customStyleFn, exporter } = createStyles(
  [
    "font-size",
    "color",
    "text-transform",
    "font-variation-settings",
    "font-family"
  ],
  "CUSTOM_",
  customStyleMap
);

// RichEditor.addEventListener("keydown", event => {
//   console.log("the key was pressed");
//   // do something
// });

class RichEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editorState: EditorState.createEmpty() };

    this.focus = () => this.refs.editor.focus();
    this.updateEditorState = editorState => this.setState({ editorState });
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  handleKeyUp(event) {
    if (!(event.key === "Enter")) {
      typeRelease(event.key);
      // console.log("key was released")
    } else {
      // console.log("key was released");
    }
    this.setState({ value: event.target.value });
  }

  render() {
    const { editorState } = this.state;
    const inlineStyles = exporter(this.state.editorState);
    const html = stateToHTML(this.state.editorState.getCurrentContent(), {
      inlineStyles
    });
    const rawContent = JSON.stringify(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    //console.log(rawContent);

    const content = [this.state.editorState.getCurrentContent(), inlineStyles];

    var z_last = 0;
    onkeydown = event => {
      try {
        if (
          !(event.key === "Enter" && !event.shiftKey) &&
          !this.state.displayFeedbackModal
        ) {
          var tinfo = typing(event.key, !!this.state.surveyCompleted);

          // console.log(tinfo[1])
          const newEditorState = styles.fontVariationSettings.add(
            this.state.editorState,
            tinfo[1]
          );
          recordNo = recordNo + 1;

          return this.updateEditorState(newEditorState);
        }
      } catch {}
    };

    onkeyup = event => {
      if (!(event.key === "Enter")) {
        typeRelease(event.key);
      } else {
        // console.log("key was released");
      }
    };
    var totSent = 0;

    const handleSendMessage = command => {
      // let append = "!!"
      //getSpecData();
      // console.log(append);
      // console.log(getSpecData());
      this.props.sendMessage(html);
      if (this.state.totSent === 2) {
        this.setState({
          displayFeedbackModal: true
        });
      }
      const newEditorState = clearEditorContent(this.state.editorState);
      this.updateEditorState(newEditorState);

      this.setState({
        totSent: this.state.totSent ? this.state.totSent + 1 : 1
      });
      return "handled";
    };

    const handleKeyCommand = command => {
      if (command === "digitalpenmanship-send") {
        const append = getSpecData();
        // console.log(append);

        try {
          this.props.sendMessage(html + "|specs|" + append);
          if (this.state.totSent === 5) {
            this.setState({
              displayFeedbackModal: true
            });
          }
          // console.log(content);
          const newEditorState = clearEditorContent(this.state.editorState);
          this.updateEditorState(newEditorState);
          this.setState({
            totSent: this.state.totSent ? this.state.totSent + 1 : 1
          });

          //console.log(
          //   this.state.surveyCompleted ? "SURVEY COMPLETE" : "NO SURVEY NERD"
          // );

          return "handled";
        } catch {
          this.setState({
            displayErrorModal: true
          });
        }
      }
      return "not-handled";
    };

    const myKeyBindingFunction = e => {
      if (e.key === "Enter" && !e.shiftKey) {
        return "digitalpenmanship-send";
      }
      if (e.key === "z") {
        //console.log("the Z came over to the dark side for a bit");
        // const newEditorState = styles.fontFamily.add(
        //   this.state.editorState,
        //   "RobotoFlex"
        // );
      }

      return getDefaultKeyBinding(e);
    };

    return (
      <div style={{ display: "flex", padding: "15px", width: "100%" }}>
        <div style={{ width: "80%", marginLeft: "auto" }}>
          {/* <h2>Draft-JS Editor</h2> */}

          <Editor
            customStyleFn={customStyleFn}
            customStyleMap={customStyleMap}
            editorState={editorState}
            onChange={this.updateEditorState}
            onTab={this.onTab}
            onKeyUp={this.handleKeyUp}
            placeholder={"Input message here..."}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={myKeyBindingFunction}
            // spellCheck
          />
        </div>
        <div style={{ flex: "1 1 25%" }}>
          <Button
            id="sendMessage"
            variant="primary"
            onClick={handleSendMessage}
            style={{ marginBottom: "0" }}
          >
            Send
          </Button>

          <FeedbackModalPop
            display={this.state.displayFeedbackModal}
            close={() => this.setState({ displayFeedbackModal: false })}
            completeSurvey={() => this.setState({ surveyCompleted: true })}
          />

          <ErrorModal
            hideButton={true}
            display={this.state.displayErrorModal}
            close={() => this.setState({ displayErrorModal: false })}
          />
        </div>
        {/* <div style={{ flex: "1 0 25%" }}>
          <h2>Exported To HTML</h2>
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }} />
        </div> */}
        {/* <div style={{ flex: "1 0 25%" }}>
          <h2>ContentState</h2>
          <div>
            <pre>
              {JSON.stringify(
                convertToRaw(this.state.editorState.getCurrentContent()),
                null,
                2
              )}
            </pre>
          </div>
        </div> */}
      </div>
    );
  }
}

export default RichEditor;

import { ThunkAction } from "main/storeTypes";
import { enrolling, enrollmentSucceeded } from "./enrollmentModel";

export const enroll = (uuid: string): ThunkAction<Promise<void>> => {
  return dispatch => {
    dispatch(enrolling());

    // ensure that the current user exists while also populating the store
    // with their information.
    return new Promise(() => {
      dispatch(enrollmentSucceeded({ enrolled: true }));
    });
  };
};

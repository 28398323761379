import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { DOMPurify } from "dompurify";
import _ from "lodash";
import { ModalPage } from "../modal-page/ModalPage";

const IntroModal = props => {
  const [show, setShow] = useState(true);
  //console.log("it was clicked");
  const handleClose = () => {
    setShow(false);
    if (props.close) {
      props.close();
    }
  };

  const handleShow = () => setShow(true);

  const handleCallback = childData => setShow(childData);

  // const modalContent =
  //   " <"h3"> Thank you for taking the time to participate in the user testing of my application: Digital Penmanship. </"h3">";

  return (
    <>
      <Modal show={show || props.display} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Digital Penmanship</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalPage parentCallback={handleCallback}></ModalPage>
        </Modal.Body>
      </Modal>
    </>
  );
};

export { IntroModal };

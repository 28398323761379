import datas from "./datas/log_new.json";
import { fullLog } from "./typing";

// key press duration and latencies, typing rate, and typing pressure
//Meanwhile, cluster analysis is the technique of collecting similar characteristics
//pattern vectors together. The aim is to gather information about keystroke feature
//data in order to form a relatively homogeneous cluster [16]. Feature data categorized
//within a homogeneous cluster are very similar to each other but highly dissimilar to other
//clusters. K-mean [17, 31, 119] and fuzzy c-means [71] fall within this category.

// D = R1 - P1
//Ftype1 = P2 - R1
//Ftype2 = R2 - R1
//Ftype3 = P2 - P1
//Ftype4 = R2 - P1

// var captData = [];
var tofRegData = [];

// var temp = [];

// var captCount = 0;
// var pressNo = 1;

var cap = 0;
// var strokeLog = [];

//function for determining the time
function myTime() {
  var d = new Date();
  var startTime = d.getTime();

  return startTime;
}

//calculate the time of flight value
function tofCalc(key, pressNo) {
  try {
    if (pressNo < 2) {
    } else {
      //time of flight is equal to the pressTime of the second key minus the release time of the first key
      var sTa = fullLog[pressNo].start;
      var rTb = fullLog[pressNo - 1].release;

      var tof = sTa - rTb;
      if (tof === undefined || tof === null || isNaN(tof)) {
      } else {
        tofRegData.push({
          id: pressNo,
          first_key: fullLog[pressNo - 1].key,
          second_key: fullLog[pressNo].key,
          start: sTa,
          end: rTb,
          timeofflight: tof
        });
        // console.log(tofRegData)
        fullLog[pressNo - 1].tof = tof;
      }
      dataMaintenance();
      // console.log(tofRegData);
    }
  } catch {}
}

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}
var sampleSet = [];

function dataLoad() {
  var sampleSize = 30;
  for (var i = 0; i < sampleSize; i += 1) {
    var randX = getRandomInt(306);
    // eslint-disable-next-line
    let dscope = {
      x: randX
    };
    // console.log(datas[randX]);
    tofRegData.push(datas[randX]);
  }

  // tofRegData = sampleSet;
  // console.log(tofRegData);
}

// function fillIn(key, dict, releaseTime) {
//   temp.push({
//     press: key,
//     start: dict[key],
//     number: pressNo,
//     release: releaseTime
//   });
// }

function sampleGen() {
  var sampleSize = 30;
  // console.log(dataNew.length);
  for (var i = 0; i < sampleSize; i += 1) {
    var randX = getRandomInt(306);
    // eslint-disable-next-line
    let dscope = {
      x: randX
    };
    // var sampleSet = [];
    tofRegData.push(datas[randX]);
  }
  // tofRegData = sampleSet;
  // console.log(sampleSet);
  cap = tofRegData.length; //get the length of the array at this point so that a 'cap' can be generated to ensure that the list will never exceed that length
}

var counter = 1;
function dataMaintenance() {
  //if the counter is equal to 1 then it is the first time the function is running
  //set the initial cap to the length of tofRegData so that we can fully replace the starting data set
  if (counter === 1) {
    //  console.log(dataLoad());
    cap = tofRegData.length;
    counter++;
  }
  if (counter <= cap) {
    tofRegData.shift();
  }
}

export { sampleGen, myTime, dataLoad, tofCalc, tofRegData };

// import { avgDwell, keyDwell, dwellTime } from "./font-interpolation/dwell-time";
import { dataLoad, tofCalc } from "./data-mapping";
import {
  compWeight,
  dwellTm,
  patVariance
} from "./font-interpolation/weight-mapping";
// eslint-disable-next-line
import { map_variance } from "./font-interpolation";
import { widthVar } from "./font-interpolation/width-mapping";
import { strokeString } from "./font-interpolation/letter-angle";
import { renderFeatures } from "./features";
// import { clearArchive } from "./delete";

//keypress and release
var pressTime;
var pressLog = {};
var releaseTime;
// var vwidth = 300;
//Create Dictionary with Object
// eslint-disable-next-line
var strokeLog = {};
var dict = [];
// var recordNo = [];
// var rate;
var press = 0;
var fullLog = {};
// var newMessage = [];
var pNumCatch = {};
var kScore = 0;

function typing(key) {
  if (press === 1) {
    dataLoad();
    // clearArchive();
    // console.log("this ran");
  }
  //   biometrics: [],
  //   fontVariation: [],
  //   weightMapVals:[]
  // })
  //console.log("testing this");

  if (dict[key]) {
  } else {
    press++;
    kScore++;
    // console.log(kScore);
    var dstart = new Date(); //register the date that the key is first being pressed
    pressTime = dstart.getTime(); //pull the time that the key was first pressed and save that time as pressTime

    pressLog[press] = pressTime;

    fullLog[press] = {};

    dict[key] = pressTime; //adds the pressed key to the dictionary, and saves the time the letter is pressed to the key
    var slant = strokeString(key, press, kScore);
    var thickVals = compWeight(key, pressTime, press, pressLog);
    var mono = patVariance();
    const vSize = widthVar(key, press);

    var fontSettings;

    fontSettings =
      "'wght' " +
      roundVal(thickVals[0]) +
      // ", 'SPAC'" +
      // roundVal(vSize[1]) +
      // ", 'HGHT' " +
      // roundVal(vSize[2]) +
      // ", 'CURV'" +
      // roundVal(thickVals[1]) +
      ", 'wdth'" +
      roundVal(vSize[1]) +
      ", 'slnt' " +
      roundVal(slant);

    // console.log(fullLog);
    //   key: key,
    //   start: pressTime,
    //   release: 0,
    //   dwellTime: 0,
    //   t0f: 0
    // }

    fullLog[press].key = key;
    fullLog[press].start = pressTime;

    fullLog[press].weight = thickVals[0];
    fullLog[press].letterSlant = slant;

    // fullLog[press].fontVariation.height = vSize[0];
    fullLog[press].mono = mono;
    fullLog[press].curve = thickVals[1];
    // fullLog[press].fontVariation.height = vSize[2];
    fullLog[press].descender = vSize[3];
    fullLog[press].width = vSize[1];

    pNumCatch[key] = press;

    return {
      1: fontSettings
    };
  }
}

function roundVal(number) {
  return Math.round(number * 10) / 10;
}

function typeRelease(key) {
  if (key === "enter") {
  } else {
    kScore = kScore - 1;
    // console.log(kScore);
    delete dict[key]; //when the key is released, delete the key

    try {
      var pressNo = pNumCatch[key];
      var startTime = fullLog[press].start;
      var dend = new Date();
      releaseTime = dend.getTime(); //release time is equal to the time that the key was released
      // var keyDwellTm = keyDwell(releaseTime, key, startTime);
      tofCalc(key, pressNo);
      dwellTm(pressNo, key, startTime, releaseTime);
      // dwellTm(key, startTime, releaseTime);
      // const gdTime = avgDwell(releaseTime, startTime);
      // vwidth = map_variance(gdTime, 0, 0.25, 25, 151); //using the variance mapping function to map the global average dwellTime onto the width of the individual characters
      // console.log("this was passed here")
      fullLog[pressNo].dwellTime = releaseTime - startTime;
      fullLog[pressNo].release = releaseTime;

      renderFeatures(fullLog, pressNo);
      // console.log("and it made it here")

      // console.log(fullLog[pressNo])
    } catch {}
  }
  // console.log("this is the update");
  // console.log(dict[key]);
}

export { typeRelease, typing, dict, fullLog };

import { createSelector } from "reselect";
import { AppState } from "main/storeTypes";
import { AppActions } from "main/AppActions";

export const ENROLLING = "ENROLLING";
export const ENROLLMENT_SUCCEEDED = "ENROLLMENT_SUCCEEDED";

export interface enrollingAction {
  type: typeof ENROLLING;
}

export const enrolling = (): enrollingAction => ({
  type: ENROLLING
});

type enrollmentSucceededPayloadType = {
  enrolled?: boolean;
};

export interface enrollmentSucceededAction {
  type: typeof ENROLLMENT_SUCCEEDED;
  payload: enrollmentSucceededPayloadType;
}

export const enrollmentSucceeded = (
  enrollmentSucceededPayload: enrollmentSucceededPayloadType
): enrollmentSucceededAction => ({
  type: ENROLLMENT_SUCCEEDED,
  payload: enrollmentSucceededPayload
});

export interface EnrollmentState {
  isenrolling: boolean;
  enrolled: boolean;
}

const initialState: EnrollmentState = {
  isenrolling: false,
  enrolled: false
};

const EnrollmentStateReducer = (
  state: EnrollmentState = initialState,
  action: AppActions
): EnrollmentState => {
  switch (action.type) {
    case ENROLLING: {
      return { ...state, isenrolling: true };
    }
    case ENROLLMENT_SUCCEEDED: {
      return {
        ...state,
        isenrolling: false,
        enrolled: true
      };
    }
    default:
      return state;
  }
};

const getEnrollmentStateSlice = (state: AppState) => state.enrollment;

export const isUserEnrolled = createSelector(
  getEnrollmentStateSlice,
  (enrollmentState: EnrollmentState): boolean => {
    return !!enrollmentState.enrolled;
  }
);

export const isenrolling = createSelector(
  getEnrollmentStateSlice,
  (enrollmentState: EnrollmentState): boolean => {
    return enrollmentState.isenrolling;
  }
);

export { EnrollmentStateReducer };

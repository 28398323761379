import React, { useState, useEffect } from "react";
import { CurrentConversation } from "features/currentConversation/CurrentConversation/CurrentConversation";
import { About } from "features/About/About";
import { Feedback } from "features/Feedback/Feedback";

interface IsecondChildProps {
  count: string;
}

const NavPage: React.FC<IsecondChildProps> = ({ count }) => {
  if (count === "1") {
    return <CurrentConversation />;
    // if temp is on/above boiling, it's a gas
  } else if (count === "2") {
    return <About />;

    // otherwise it's just a liquid
  } else {
    return <Feedback />;
  }
};

export { NavPage };

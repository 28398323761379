import React, { useState, createClass } from "react";
import { Modal } from "react-bootstrap";
import { DOMPurify } from "dompurify";
import _ from "lodash";

const ModalContent = {
  pages: [
    {
      name: "page1",
      content: [
        {
          type: "h3",
          class: "intro_header",
          content:
            "Thank you for taking the time to participate in the user testing of my application: Digital Penmanship."
        },
        {
          type: "p",
          class: "intro_body",
          content:
            " <b>Digital Penmanship</b> is a chat based application that has been created to showcase a technology that I have developed to fulfill the practice component of my doctoral dissertation at Rensselaer Polytechnic Institute (RPI) in Upstate, NY."
        }
        // {
        //   type: "button",
        //   class: "",
        //   OnClick: "this.changePage",
        //   content: "Continue"
        // }
      ]
    },
    {
      name: "page2",
      content: [
        {
          type: "p",
          class: "intro_body",
          content:
            "<b>Digital Penmanship</b> was designed to allow for the inclusion of the author within their messages. Research has demonstrated that every person has their own unique way of typing, and by capturing a users keystroke data we can successfully verify an individual's identity. Although you may not be aware of it, the applications that we use every day are capturing data on how we interact with their system, including your keystroke data."
        },
        {
          type: "p",
          class: "intro_body",
          content:
            "The goal behind <b>Digital Penmanship</b> is to take back ownership of our biometric data within the digital platforms that we use, and repurpose it as a way to solve issues surrounding miscommunication and authorship within social media and chat based applications."
        }
        // {
        //   type: "button",
        //   class: "",
        //   OnClick: "this.changePage",
        //   content: "Continue"
        // }
      ]
    },

    {
      name: "page3",
      content: [
        {
          type: "p",
          class: "intro_body",
          content:
            "To participate in the study, all you have to do is click into the text-box at the bottom of the page and start typing."
        },
        {
          type: "p",
          class: "intro_body",
          content:
            "As you type, you should start to notice that the letters that are being shown on the screen don't all look the same. Some might be angled to the right or left, others might be super bold, etc."
        },
        {
          type: "p",
          class: "intro_body",
          content: "<b>Don't Panic</b> This is by design."
        },
        {
          type: "p",
          class: "intro_body",
          content:
            "<span style='color: #7E5A7E'><b>Why are the letters changing?</b></span> The letters are changing to respond to your keystrokes. It looks at the speed you are typing, where the letters are on the keyboard, how long keys are pressed for, etc. and maps your type data onto the variation axes of a variable font."
        },
        {
          type: "p",
          class: "intro_body",
          content:
            "<span style='color: #7E5A7E'><b>But wait, doesn't that mean you are also storing/tracking my data?</b></span>  Going into this project, I made a concious decision to design the system in a way that would be 100% responsive without needing to store or collect any user data to function. This means that each time you visit the page a new 'baseline' or 'dataset' is generated for you, and then when you close the browser window, your history is cleared. The trade off for not maintaining a data profile for each user is that as the program generates your new profile, you may experience some drastic changes in how the characters are rendered. This should only last for the first couple of letters, and then they should begin to regulate."
        },
        {
          type: "p",
          class: "intro_body",
          content:
            "<b>Disclaimers</b> <br/>Please note, that because this is a user test, your data is being collected for later analysis, but it in no way can be tied back to you and will not be shared with anyone aside from the members of my committee for assistance during analysis. <br/>Although the application works on mobile and web browsers, it has been designed for standard keyboards and not touchscreens. While you will see changes on touchscreen devices, I am unsure of the accuracy of the changes that you will be shown. If possible, I recommend using the application with a standard or mechanical computer keyboard."
        }
        // {
        //   type: "button",
        //   class: "",
        //   OnClick: "this.onTrigger",
        //   content: "Get to Typing!"
        // }
      ]
    }
  ]
};
let count = 1;

function parseStuff(count) {
  let mContent = "";
  if (count === 1) {
    mContent = ModalContent.pages[0].content;

    // if temp is on/above boiling, it's a gas
  } else if (count === 2) {
    mContent = ModalContent.pages[1].content;

    // otherwise it's just a liquid
  } else {
    mContent = ModalContent.pages[2].content;
  }

  let typeString = "";
  _.forEach(mContent, (inside, outside) => {
    if (inside.type === "button") {
      const type = inside.type;
      const objclass = inside.class;
      const objContent = inside.content;
      const objClick = inside.OnClick;

      typeString = (
        typeString +
        "<button onClick = '" +
        objClick +
        "'>" +
        objContent +
        "<" +
        type +
        "/>"
      ).toString();
    } else {
      const type = inside.type;
      const objclass = inside.class;
      const objContent = inside.content;

      typeString = (
        typeString +
        "<" +
        type +
        " class = '" +
        objclass +
        "'>" +
        objContent +
        "<" +
        type +
        "/>"
      ).toString();
    }
  });
  // console.log(typeString);
  return { __html: typeString };
}

class ModalPage extends React.Component {
  state = {
    content: parseStuff(count)
  };

  changePage = () => {
    count++;
    if (count <= 3) {
      const newStuff = parseStuff(count);
      this.setState({ content: newStuff });
    } else {
      this.props.parentCallback(false);
    }
  };
  render() {
    return (
      <>
        <div>
          {/* <button class="backButton" onClick=>Back</button> */}
          <div dangerouslySetInnerHTML={this.state.content} />
          <button onClick={this.changePage}>Continue</button>
        </div>
      </>
    );
  }
}

export { ModalPage };

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { purple } from "@material-ui/core/colors";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { appTheme } from "../../../main/Theme";
import GlobalStyles from "../../../main/styles/GlobalStyles";

const PurpleSwitch = withStyles({
  switchBase: {
    color: "black",
    "&$checked": {
      color: "#7E5A7E"
    },
    "&$checked + $track": {
      backgroundColor: "#7E5A7E"
    }
  },
  checked: {},
  track: {}
})(Switch);

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: "none"
  },
  paper: {
    padding: theme.spacing(1)
  }
}));

export default function CustomizedSwitches() {
  const [state, setState] = React.useState({
    checkedA: true
  });

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if (state.checkedA === false) {
      // Try changing the value of this CSS variable,
      // and check out the blog's logo!
      document.documentElement.style.setProperty("--font", '"GrtskVariable"');
    } else if (state.checkedA === true) {
      document.documentElement.style.setProperty("--font", '"Helvetica"');
    }
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <PurpleSwitch
            checked={state.checkedA}
            onChange={handleChange}
            name="checkedA"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          />
        }
        label="Toggle Individuality"
      />
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>
          {" "}
          Toggle to see how the chat would look in a standard text-based
          communication system that does not use the Digital Penmanship
          technology.
        </Typography>
      </Popover>
    </FormGroup>
  );
}

import React, { useState } from "react";
import { Menu } from "features/chat/Menu/Menu";
import { CurrentConversation } from "features/currentConversation/CurrentConversation/CurrentConversation";
import { ConversationMembers } from "features/conversationMembers/ConversationMembers/ConversationMembers";
import { JoinConversationDialog } from "features/joinedConversations/JoinConversationDialog/JoinConversationDialog";
import { FlexRow } from "foundations/components/layout";
import { IntroModal } from "../../bootstrap/intro-modal/intro-modal";
import { NavPage } from "../../nav-page/NavPage";
import { Testing } from "features/testing/Testing";
// import { FeedbackModal } from "../../bootstrap/feedback-modal/FeedbackModal";

const ChatUI: React.FC = () => {
  const [parentCount, setParentCount] = useState<string>("1");

  const updateCount = (count: string): void => {
    setParentCount(count);
  };

  return (
    <FlexRow height="100%">
      <IntroModal></IntroModal>
      <Menu count={parentCount} updateCount={updateCount} />
      {/* <Testing /> */}
      <NavPage count={parentCount} />
    </FlexRow>
  );
};

export { ChatUI };

import React from "react";
import DOMPurify from "dompurify";
import DataPopover from "features/bootstrap/data-popover/DataPopover";
import _ from "lodash";
// function parseData(myMessage){
//   let weight = {};
//   const originalString = myMessage;
//   const splitString = originalString.split("</span>");

//   _.forEach(splitString, (inside, outside) => {

//     const newStuff = inside.slice(37);
//     const newStuffDivided = newStuff.split(",");
//     _.forEach(newStuffDivided, (inside, outside) => {
//         const newerStuff = inside.slice(0);
//         const dividedMore = newerStuff.split("'");
//         _.forEach(dividedMore, (inside, outside) => {
//           if (inside[0] ==="wght"){

//           }

//         });

//     });

//   });

// console.log(messageSpecs);

// }

export const TextMessage = ({ text }) => {
  const newMessage = text;
  const divide = newMessage.split("|specs|");
  const myMessage = divide[0];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // parseData(myMessage);
  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
    // console.log(open);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  if (divide.length === 2) {
    return (
      <>
        <div className="wrapper">
          <div
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(myMessage) }}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          ></div>
          <DataPopover content={divide[1]} anchorEl={anchorEl} />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="wrapper">
          <div
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(myMessage) }}
          ></div>
        </div>
      </>
    );
  }
};

// import React from "react";
// import DOMPurify from "dompurify";

// import {
//   Editor,
//   // eslint-disable-next-line
//   EditorState,
//   convertToRaw,
//   // eslint-disable-next-line
//   ContentState,
//   // eslint-disable-next-line
//   RichUtils,
//   // eslint-disable-next-line
//   AtomicBlockUtils,
//   // eslint-disable-next-line
//   ContentBlock,
//   // eslint-disable-next-line
//   CharacterMetadata,
//   // eslint-disable-next-line
//   getDefaultKeyBinding
// } from "draft-js";
// import { stateToHTML } from "draft-js-export-html";
// import createStyles from "draft-js-custom-styles";
// // eslint-disable-next-line
// import "draft-js/dist/Draft.css";

// const customStyleMap = {
//   MARK: {
//     backgroundColor: "Yellow",
//     fontStyle: "italic"
//   }
// };

// // Passing the customStyleMap is optional
// const { styles, customStyleFn, exporter } = createStyles(
//   ["font-size", "color", "text-transform", "font-variation-settings"],
//   "CUSTOM_",
//   customStyleMap
// );

// export const TextMessage = ({ text }) => {

//   try{
//   const myMessage = text;
//   const { editorState } = this.state;
//   const inlineStyles = exporter(this.state.editorState);
//   console.log(inlineStyles);
//   const html = stateToHTML(this.state.text.getCurrentContent(), {
//     inlineStyles
//   });

//   return (
//     <>
//       <div className="wrapper">
//         <div
//           dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}
//         ></div>
//       </div>
//     </>
//   );
//   } catch{
//     const myMessage = text;

//     return(
//       <>
//       <div className="wrapper">
//         <div
//           dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(myMessage) }}
//         ></div>
//       </div>
//     </>
//     );
//   }
// };

import * as math from "mathjs";
import _ from "lodash";
import { renderGraph, dataEqs } from "./render-graph";

var m = 0;

// The derivative of a function y = f(x) of a variable x is a measure of the rate at which the value y of the function changes with respect to the change of the variable x. It is called the derivative of f with respect to x.
// The sign of the first derivative tells you whether a function is increasing or decreasing. The sign of the second derivative tells you whether a function is concave up or concave down. "Inflection point" means a point where the second derivative changes sign.
// The second derivative may be used to determine local extrema of a function under certain conditions. If a function has a critical point for which f′(x) = 0 and the second derivative is positive at this point, then f has a local minimum here.

const e = 2.71828;

function rocRender(value, pass) {
  // if (!isNaN(value[0]) && !isNaN(value[1])) {
  // console.log("this actually did run")
  var yTOF = value;

  var xTOF;
  //set the equation for the polynomial regresion
  var eq = dataEqs[dataEqs.length - 1].log;
  // console.log(eq.toString());

  //var str = eq.toString();
  var eq1Fix = eq.replace(" ln", "*log");
  var eqFix = eq1Fix.replace("y = ", "");
  //const equationCalc = math.parse(eqFix);

  //parser.evaluate(eqFix)
  var eqRash = math.simplify(eqFix);
  //console.log(eqRash.toString());
  var eqInverse = invertEq(eqRash);
  // console.log(eqInverse);
  var equationCalc = eqRash;

  let scope = {
    y: yTOF
  };

  xTOF = math.evaluate(eqInverse, scope);
  // console.log(xTOF);
  m = math.derivative(equationCalc, "x").evaluate({ x: xTOF });
  // if (xTOF===!isNan){
  //   console.log("it was NAN")
  // }
  var derivEq = math.derivative(equationCalc, "x");

  //var simpderiv = math.simplify(deriv).toString;
  var derivEqString = derivEq.toString();

  if (pass < 1) {
    var slopeExt = derivGraph(derivEqString);
  }
  // console.log("this was passed to here 2")

  // console.log("the deriv is equal to " + derivEqString);
  // var invDeriv = algebra.parse(derivEqString + "=" + 0);
  //console.log(derivEq);

  eqInverse = derivInverse(derivEq);
  // eslint-disable-next-line
  let dscope = {
    y: 0
  };

  // var critPoint = math.evaluate(eqInverse, dscope);

  // console.log("critical points are at " + critPoint);

  // var cVal = 0;

  // var secDerivEq = criticalPoints(derivEq);

  // x = 1;

  return {
    slope: m
  };
  // }

  return false;
}
var a;
var b;
function derivInverse(strDeriv) {
  strDeriv = strDeriv.toString();
  // console.log(strDeriv);
  var indices = [];

  for (var i = 0; i < strDeriv.length; i++) {
    if (strDeriv[i] === "/") indices.push(i);
  }

  var dcalculation = [];

  for (i = 0; i < indices.length; i += 1) {
    var dpiece = [];
    var dpieces = [];
    // console.log("this was passed to here 3");

    if (i < 1) {
      a = 0;
      b = indices[i];
      dpiece = strDeriv.slice(a, b);
    } else {
      a = indices[i];
      //  console.log(a);
      b = indices[i - 1];
      //  console.log(b);
      dpiece = strDeriv.slice(b, a);
    }

    var dleftEq = [];
    dleftEq = "y";
    var n = 0;

    dpieces = "*" + "(" + dpiece.slice(1, dpiece.length) + ")";
    //  console.log(dpieces);
    dcalculation.push(dpieces.toString());

    // eslint-disable-next-line
    _.forEach(dcalculation, (inside, outside) => {
      const store = [];

      store.push(dleftEq);
      store.push(dcalculation[n]);

      dleftEq = "(" + store[0] + store[1] + ")";

      //console.log(dleftEq);
      n++;
    });
  }

  return dleftEq;
}

// function criticalPoints(eq) {
//   var secDeriv = math.derivative(eq, "x");
//   var secDerivClean = math.simplify(secDeriv);
//   var secDerivInverse = derivInverse(secDerivClean);

//   let dscope = {
//     y: 0
//   };

//   var seccritPoint = math.sqrt(math.evaluate(secDerivInverse, dscope));

//   return seccritPoint;
// }

function invertEq(str) {
  var storing = [];

  var splitters = ["-", "+", "*"];
  str = str.toString();
  //console.log(str);
  var editsParm = [];
  var spot = 0;
  _.forEach(splitters, (inside, outside) => {
    var location = str.indexOf(inside);
    spot = spot + 1;
    if (location < 0) {
    } else {
      editsParm.push({
        symbol: inside,
        index: location,
        loc: spot
      });

      //  console.log(editsParm)
    }
  });

  var i;
  var calculation = [];
  var calcDefer = [];
  var stopper;
  if (editsParm.length >= 3) {
    var tempEdits = editsParm;

    tempEdits.sort(function (a, b) {
      return a.index - b.index;
    });
    //  console.log('these are my temp edits')
    //  console.log(tempEdits);

    var di = 0;

    _.forEach(tempEdits, (inside, outside) => {
      di = di + 1;
      storing.push({
        index: inside.index,
        symbol: inside.symbol,
        spot: inside.loc,
        id: di
      });
      storing.sort(function (a, b) {
        return a.spot - b.spot;
      });
      editsParm = storing;
      // console.log(editsParm);
    });
  }

  for (i = 0; i < editsParm.length - 1; i += 1) {
    var piece = [];
    var pieces = [];
    // console.log("this is starting " + editsParm[i]);

    if (editsParm.length === 3) {
      if (editsParm[i].id > i) {
        if (editsParm[i].id === 3) {
          var b = str.length;
          var a = editsParm[i].index;
          piece = str.slice(a, b);
          // console.log('zCon was equal to 3 and b is equal to ' + b);
          // console.log('output piece is going to be' + piece)
        } else if (editsParm[i].id !== 3) {
          var zPos = editsParm[i].id;
          // console.log(editsParm[i])
          // console.log(zPos);
          // eslint-disable-next-line
          var item = _.forEach(editsParm, (inside, outside) => {
            if (inside.id === zPos + 1) {
              stopper = inside.index;
            }
          });

          b = stopper;
          a = editsParm[i].index;
          piece = str.slice(a, b);
          // console.log('pieces are below');
          // console.log(piece);
        }
      } else if (editsParm[i].id < i) {
        zPos = editsParm[i].id;
        // eslint-disable-next-line
        item = _.forEach(editsParm, (inside, outside) => {
          if (inside.id === zPos + 1) {
            stopper = inside.index;
          }
        });
        b = stopper;
        a = editsParm[i].index;
        piece = str.slice(a, b);
        // console.log('zCon was equal to ' + zCon + 'and b is equal to ' + b);
        // console.log('output piece is going to be' + piece)
      } else if (editsParm[i].id === i) {
        if (i === 2) {
          b = str.length;
          a = editsParm[i].index;
          piece = str.slice(a, b);
        } else {
          b = editsParm[i + 1].index;
          a = editsParm[i].index;
          piece = str.slice(a, b);
          // console.log('zCon was equal to ' + zCon + 'and b is equal to ' + b);
          // console.log('output piece is going to be' + piece)
        }
      }
    } else if (editsParm[0].index > editsParm[1].index) {
      b = str.length;
      a = editsParm[i].index;
      piece = str.slice(a, b);

      // console.log('0 was higher than 1')
    } else {
      if ((i = 1)) {
        b = str.length;
        a = editsParm[i].index;
        piece = str.slice(a, b);
      } else {
        b = editsParm[i + 1].index;
        a = editsParm[i].index;
        // console.log('1 was greater than 0')
        piece = str.slice(a, b);
      }
    }

    //console.log("this is what is going into the edits Parm stuff");
    // console.log(piece);

    if (editsParm[i].symbol === "-") {
      pieces = "+" + "(" + piece.slice(1, piece.length) + ")";

      // console.log("pieces 1");
      // console.log(pieces);
      calculation.push(pieces.toString());
    } else if (editsParm[i].symbol === "+") {
      pieces = "-" + "(" + piece.slice(1, piece.length) + ")";

      // console.log("pieces 2");
      // console.log(pieces);
      calculation.push(pieces.toString());
    } else if (editsParm[i].symbol === "*") {
      var mlogCheck = pieces.includes("log");
      if (mlogCheck === true) {
        // console.log("log was here" + editsParm[i]);
        pieces = "(" + piece.slice(1, piece.length) + "))";
      } else {
        pieces = "/" + "(" + piece.slice(1, piece.length) + ")";

        // console.log("pieces 3");
        // console.log(pieces);
        calculation.push(pieces.toString());
      }
    } else if (editsParm[i].symbol === "/") {
      pieces = "*" + "(" + piece.slice(1, piece.length) + ")";
      // console.log("pieces 4");

      // console.log(pieces);
      calculation.push(pieces.toString());
    }

    var logCheck = pieces.includes("log");

    if (logCheck === true) {
      calculation.splice(-1, 1);
      calcDefer.push("e" + "^" + "(" + "leftEq" + ")");
      // console.log(calcDefer);
    }

    if (i >= 2) {
      calculation.push(calcDefer[0]);
    }

    // console.log("calculation " + calculation);
  }
  var leftEq = [];
  leftEq = "y";
  var n = 0;

  _.forEach(calculation, (inside, outside) => {
    const store = [];

    store.push(leftEq);
    store.push(calculation[n]);

    var eCheck = store[1].includes("e");

    if (eCheck === true) {
      leftEq = e + "^" + "(" + store[0] + ")";
    } else {
      leftEq = "(" + store[0] + store[1] + ")";
    }

    //console.log(leftEq);
    n = n + 1;
  });

  //console.log("leftEq = " + leftEq)

  return leftEq;
}

// function tanEqX(m, xTOF, yTOF, x) {
//   var y = m * x - m * xTOF - yTOF;
//   return y;
// }

// function tanEqY(m, xTOF, yTOF, y) {
//   var x = (y - yTOF) / m + xTOF;
//   return x;
// }

//graphing the derivative
function derivGraph(derivEq) {
  var derivPts = [];

  var sampleSize = 30;
  // console.log(dataNew.length);
  for (var i = 0; i < sampleSize; i += 1) {
    var randX = getRandomInt(300);
    let dscope = {
      x: randX
    };

    // var critPoint = math.evaluate(eqInverse, dscope);
    var yVal = math.evaluate(derivEq, dscope);

    derivPts.push([yVal, randX]);
  }

  // console.log(derivPts);
  var dvPts = [];

  var sortdvPts = [];
  sortdvPts = derivPts.sort(function (a, b) {
    return +a[0] - +b[0];
  });

  _.forEach(sortdvPts, (inside, outside) => {
    // h = h + 1;
    dvPts.push([inside[0], inside[1]]);
  });
  // console.log("sorted " + sortdvPts);
  renderGraph(dvPts, 0);
  var maxMin = [];
  maxMin.push(dvPts[0][0]);
  maxMin.push(dvPts[dvPts.length - 1][0]);
  // console.log(maxMin);

  return maxMin;
}

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

export { rocRender };

import { createGlobalStyle } from "styled-components";
import { Theme } from "../Theme";

const GlobalStyles = createGlobalStyle<{ theme: Theme }>`

  body {
    color: ${({ theme }) => theme.colors.importantText};
    margin: ${({ theme }) => theme.space[0]};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    box-sizing: border-box;
    margin: 0;
  }
  .eYUofl{
    box-shadow: 2px 6px 20px 0px rgb(0 0 0 / 14%);
  }

  body,
  html,
  #root {
    height: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export default GlobalStyles;

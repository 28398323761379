import React from "react";
import { FlexColumn } from "foundations/components/layout";

const About = () => {
  return (
    <>
      <FlexColumn
        height="100%"
        width="100%"
        position={["fixed", "static"]}
        bg="backgrounds.content"
        borderRight="light"
        style={{ maxHeight: "100vh", overflowY: "scroll", padding: "6%" }}
      >
        <h3 style={{ fontFamily: "Space Grotesk" }}>Digital Penmanship</h3>
        <p style={{ fontSize: "0.8em" }}>
          <b>Digital Penmanship</b> is a chat based application that has been
          created to showcase a technology that I have developed to fulfill the
          practice component of my doctoral dissertation at Rensselaer
          Polytechnic Institute (RPI) in Upstate, NY.
        </p>
        <p style={{ fontSize: "0.8em" }}>
          <b>Digital Penmanship</b> was designed to allow for the inclusion of
          the author within their messages. Research has demonstrated that every
          person has their own unique way of typing, and by capturing a users
          keystroke data we can successfully verify an individual's identity.
          Although you may not be aware of it, the applications that we use
          every day are capturing data on how we interact with their system,
          including your keystroke data.
        </p>
        <p style={{ fontSize: "0.8em" }}>
          The goal behind <b>Digital Penmanship</b> is to take back ownership of
          our biometric data within the digital platforms that we use, and
          repurpose it as a way to solve issues surrounding miscommunication and
          authorship within social media and chat based applications.
        </p>

        <p style={{ fontSize: "0.95em", textTransform: "uppercase" }}>
          <b>The Study:</b>
        </p>
        <p style={{ fontSize: "0.8em" }}>
          To participate in the study, all you have to do is click into the
          text-box at the bottom of the page and start typing.
        </p>

        <p style={{ fontSize: "0.8em" }}>
          As you type, you should start to notice that the letters that are
          being shown on the screen don't all look the same. Some might be
          angled to the right or left, others might be super bold, etc.
        </p>

        <p style={{ fontSize: "0.8em" }}>
          <b>Don't Panic</b> This is by design.
        </p>

        <p style={{ fontSize: "0.8em" }}>
          <span style={{ color: "#7E5A7E" }}>
            <b>Why are the letters changing?</b>
          </span>{" "}
          The letters are changing to respond to your keystrokes. It looks at
          the speed you are typing, where the letters are on the keyboard, how
          long keys are pressed for, etc. and maps your type data onto the
          variation axes of a variable font.{" "}
        </p>

        <p style={{ fontSize: "0.8em" }}>
          <span style={{ color: "#7E5A7E" }}>
            <b>
              But wait, doesn't that mean you are also storing/tracking my data?
            </b>
          </span>{" "}
          Going into this project, I made a concious decision to design the
          system in a way that would be 100% responsive without needing to store
          or collect any user data to function. This means that each time you
          visit the page a new 'baseline' or 'dataset' is generated for you, and
          then when you close the browser window, your history is cleared. The
          trade off for not maintaining a data profile for each user is that as
          the program generates your new profile, you may experience some
          drastic changes in how the characters are rendered. This should only
          last for the first couple of letters, and then they should begin to
          regulate.
        </p>

        <p style={{ fontSize: "0.95em", textTransform: "uppercase" }}>
          <b>Disclaimers</b>
        </p>
        <p style={{ fontSize: "0.8em" }}>
          Please note, that because this is a user test, your data is being
          collected for later analysis, but it in no way can be tied back to you
          and will not be shared with anyone aside from the members of my
          committee for assistance during analysis.
          <br />
          Although the application works on mobile and web browsers, it has been
          designed for standard keyboards and not touchscreens. While you will
          see changes on touchscreen devices, I am unsure of the accuracy of the
          changes that you will be shown. If possible, I recommend using the
          application with a standard or mechanical computer keyboard.
        </p>
      </FlexColumn>
    </>
  );
};

export { About };

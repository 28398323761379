import { sortData } from "../sorting-data";
import { renderGraph } from "../render-graph";
import { rocRender } from "../derivative";
import { myTime, tofRegData } from "../data-mapping";
import { mapFactor } from "./weight-mapping";
import { fullLog } from "../typing";
// import blacklist from "./blacklist.json";

//----parameters------//

let total = 0; //total number of characters that have been typed

// eslint-disable-next-line
let elapseTimeSec;

let startTime;
let currentTime;
let elapseTime;
// var width = 100;
// var height = 200;
// var vHeight;
// var height = 730;
// let ascStore = [0];
// let desStore = [0];
// var spaStore = [0];
let descender = 730;
// var height_store = 200;
let descStore = 730;
// var asc_store = 730;
let widthStore = 100;
let runner = 0;
let runLevel = 2;
let runCont;
let width = 300;
let render;
////////////////////////////   width mapping   ////////////////////////////
let past = 200;

function graphCont() {
  runner++;
  if (runner < runLevel) {
    runCont = "false";
  } else if (runner === runLevel) {
    runCont = "true";
    runLevel = runLevel * runLevel;
  }

  return runCont;
}

function widthVar(key, press) {
  total = total + 1;

  if (total <= 1) {
    myTime();
    startTime = fullLog[press];
    elapseTime = startTime;
    elapseTimeSec = elapseTime / 1000;
    // space = 200;
  } else {
    currentTime = fullLog[press];
    if (total === 2) {
      startTime = fullLog[press - 1];
    } else {
      startTime = fullLog[press - 2];
    }

    elapseTime = currentTime - startTime;
    elapseTimeSec = elapseTime / 1000;
    // width = (300-((rate*17.76)+4));
    const toRender = graphCont();

    if (toRender === "true") {
      const dataNew = sortData(tofRegData);
      render = renderGraph(dataNew, 3);
    } else {
      // console.log(render);
    }

    // render = render;
    try {
      // code...
      // console.log(tofRegData[tofRegData.length - 1]["timeofflight"]);
      const vm = rocRender(
        tofRegData[tofRegData.length - 1]["timeofflight"],
        0
      );
      if (!vm) {
        throw vm;
      }

      let vCheck = vm.slope * width;

      // var vCheck = 20;
      if (vCheck < past) {
        past = vCheck;

        vCheck = vCheck * 1;
      } else if (vCheck > past) {
        past = vCheck;
        vCheck = vCheck * -1;
      }
      // console.log("vCheck is equal to " + vCheck);

      width = vCheck + width;

      if (total <= 3) {
      } else {
        // var vSpace = vm.slope * space;
        // var vHeight = vm.slope * height;
        const vDescender = vm.slope * descender;
        // space = vSpace + space;
        // if (isNaN(space)) {
        //   space = space_store;
        //   space_store = 100;
        // }
        // spaStore.push(space);
        // height = vHeight + height;
        // if (isNaN(height)) {
        //   height = asc_store;
        //   height_store = 300;
        // }
        // ascStore.push(height);

        descender = vDescender + descender;
        if (isNaN(descender)) {
          descender = descStore;
          descStore = 730;
        }
        descStore.push(descender);
        // height = mapFactor(ascStore, height, 165, 456);
        // console.log("height is equal to" + height);
        descender = mapFactor(descStore, descender, 600, 1000);
        // console.log("descender is equal to" + descender);

        // space = mapFactor(spaStore, space, 0, 120);
        // console.log("space is equal to" + space);
      }
    } catch (err) {}
  }

  if (width < 100) {
    width = 100;
  }

  if (width > 1000) {
    width = 1000;
  }
  widthStore = width;

  if (width === undefined || width === null || isNaN(width)) {
    width = widthStore;
    widthStore = 500;
  }

  return {
    // 0: height,
    1: width,
    // 2: height,
    3: descender
  };
}

//////////////////////////// end width mapping ////////////////////////////

export { widthVar, runCont };
